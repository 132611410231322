const state = {
  notifications: [],
  id: 1,
};

const mutations = {
  add: (state, payload) => {
    state.notifications = [...state.notifications, payload];
  },
  clear: (state) => {
    state.notifications = [];
  },
  removeById: (state, id) => {
    state.notifications = state.notifications.filter(n => n.id !== id);
  },
  incrementId: (state) => {
    state.id = state.id + 1;
  }
}

const actions = {
  add: ({ commit, state }, notification) => {
    let defaultNotification = {
      title: '',
      text: '',
      type: 'info',
      time: 5
    };

    notification = { ...defaultNotification, ...notification };
    notification = { ...notification, id: state.id };
    commit('incrementId');

    if (!!notification.time && +notification.time > 0) {
      let timeout = notification.time * 1000;
      setTimeout(
        () => {
          commit('removeById', notification.id)
        },
        timeout
      );
    }
    commit('add', notification);
  },

  success: ({ dispatch }, text) => dispatch('add', { text, type: 'success' }),

  warning: ({ dispatch }, text) => dispatch('add', { text, type: 'warning' }),

  error: ({ dispatch }, text) => dispatch('add', { text, type: 'error' }),

  removeById: ({commit}, id) => commit('removeById', id),

  clear: ({commit}) => commit('clear'),
}

export const notification = {
  namespaced: true,
  state,
  mutations,
  actions,
};

<template functional>
  <svg width="24" height="24" class="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="m7.687517,3.562518l0,4.999987l14.999962,0l0,-4.999987m-14.999962,17.499955l14.999962,0l0,-4.999987l-14.999962,0m0,-1.249997l14.999962,0l0,-4.999987l-14.999962,0m-6.249984,-1.249997l4.999987,0l0,-4.999987l-4.999987,0m0,17.499955l4.999987,0l0,-4.999987l-4.999987,0m0,-1.249997l4.999987,0l0,-4.999987l-4.999987,0l0,4.999987z"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconList',
  }
</script>

export default {
  equalsFirstLevel(o1, o2, igroneKeys) {
    let [keys1, keys2] = [Object.keys(o1), Object.keys(o2)];

    if (igroneKeys && igroneKeys.length && Array.isArray(igroneKeys)) {
      keys1 = keys1.filter(key => !igroneKeys.includes(key));
      keys2 = keys2.filter(key => !igroneKeys.includes(key));
    }

    const nonEqualFieldsKeys1 = keys1.filter(key => o1[key] != o2[key]);
    const nonEqualFieldsKeys2 = keys2.filter(key => o1[key] != o2[key]);

    return nonEqualFieldsKeys1.length === 0 && nonEqualFieldsKeys2.length === 0;
  },

  async mapProperties(to, from, keysFromTo){
    return new Promise( (resolve) => {
      if (!keysFromTo) return resolve();

      Object.entries(keysFromTo)
      .forEach( ([fromKey, toKey], index, total) => {
        to[toKey] = from[fromKey];
        if (index === (total.length - 1) ) return resolve();
      });
    })
  },

  pushProperties(to, from, keysFromTo){
    if (!keysFromTo) return;

    Object.entries(keysFromTo)
    .forEach( ([fromKey, toKey]) => {
      let fromValue = from[fromKey];
      fromValue = Array.isArray(fromValue) ? fromValue : [ fromValue ];
      to[toKey].push(...fromValue);
    });
  },

  deepCopyData(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
};

import axios from 'axios';
import urlsUtils from '@/utils/urls';
import store from '@/store';

const ENDPOINT = 'comments';

export default {

  ENDPOINT,

  async getAll(filter) {
    return axios({
      method: 'get',
      url: urlsUtils.constructQueryUrl(`${ENDPOINT}/`, filter),
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error", "Comments loading error");
      return Promise.reject(error);
    });
  },

  async getNext(url) {
    return axios({
      method: 'get',
      url: url,
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error", "Comments loading error");
      return Promise.reject(error);
    });
  },

  async get(idOrIds) {
    if (Array.isArray(idOrIds)) return Promise.all( idOrIds.map(this.get) );

    return axios({
      method: 'get',
      url: `${ENDPOINT}/${idOrIds}/`,
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error", `Comment ${idOrIds} loading error`);
      return Promise.reject(error);
    });
  },

  async getData(idOrIds) {
    return this.get(idOrIds)
    .then( result => {
      if (Array.isArray(result)) return Promise.resolve(result.map( r => r.data));

      return Promise.resolve(result.data);
    })
    .catch( Promise.reject );
  },

  async count(filter) {
    return axios({
      method: 'get',
      url: urlsUtils.constructQueryUrl(`${ENDPOINT}/count`, filter),
    })
  },

  async add({
    material,
    message,
  }) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/`,
      data: {
        material,
        message,
      }
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Comment creation error`);
      return Promise.reject(error);
    });
  },

  async edit(id, {
    material,
    message,
  }) {
    return axios({
      method: 'put',
      url: `${ENDPOINT}/${id}/`,
      data: {
        material,
        message,
      }
    })
    .then( (response) => {
      store.dispatch('notification/success', `Comment saved`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Comment editing error`);
      return Promise.reject(error);
    });
  },

  async patch(id, editData) {
    return axios({
      method: 'patch',
      url: `${ENDPOINT}/${id}/`,
      data: editData,
    })
    .then( (response) => {
      store.dispatch('notification/success', `Comment saved`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Comment editing error`);
      return Promise.reject(error);
    });
  },

  async delete(id) {
    return axios({
      method: 'delete',
      url: `${ENDPOINT}/${id}/`,
    })
    .then( (response) => {
      store.dispatch('notification/success', `Comment ${id} deleted`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Comment ${id} deletion error`);
      return Promise.reject(error);
    });
  },
};

export default {
    required: v => !!v || 'Field is required',
    lenBy: len => v => v.length >= len || `Need to be at least ${len} length`,
    email: v => /.+@.+\..+/.test(v) || 'E-mail is invalid',
    counterBy: len => v => (v?.length ?? 0) <= len || `Max ${len} characters`,
    azString: v => (v.length == 0 || /^\w+$/.test(v)) || 'May contain only letters, numbers and _',
    azSpecialString: v => (v.length == 0 || /^[\w.@+-]+$/.test(v)) || 'May contain only letters, numbers and @/./+/-/_',
    containLetter: v => (v.length == 0 || /.*[a-zA-Z]+.*/.test(v)) || 'Need contain at least one letter',
    containNumber: v => (v.length == 0 || /.*\d+.*/.test(v)) || 'Need contain at least one number',
    containSpecial: v => (v.length == 0 || /.*[_.@+-]+.*/.test(v)) || 'Need contain at least one of @/./+/-/_',
    differentCase: v => (v.toUpperCase() !== v && v.toLowerCase() !== v) || 'Need contain uppercase and lowercase letters',
};

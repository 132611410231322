<template>
  <v-select
    v-bind="$props"
    v-on="$listeners"
  >
    <slot
      v-for="(_, name) in $slots"
      :name="name"
      :slot="name"
    />
    <template
      v-for="(_, slot) of $scopedSlots"
      slot-scope="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
    <template #append-outer>
      <div
        v-if="$slots['append-outer']"
        class="inputBg rounded-r"
        style="margin-left: -9px; margin-top: -7px;"
      >
        <slot name="append-outer"></slot>
      </div>
    </template>
  </v-select>
</template>

<script>
  import {
    VSelect,
  } from 'vuetify/lib';

  export default {
    name: 'AppSelect',

    extends: VSelect,

    props: {
      backgroundColor: {
        type: String,
        default: 'inputBg',
      },
      dense: {
        type: Boolean,
        default: true,
      },
      flat: {
        type: Boolean,
        default: true,
      },
      solo: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style scoped>
  .v-autocomplete__content .v-list-item__content .v-list-item__title {
    font-size: 1rem !important;
  }
</style>
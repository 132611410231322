import axios from 'axios';
import requestsUtils from '@/utils/requests';
import browserActions from '@/utils/browserActions';
import config from '@/config';

const ENDPOINT = 'auth';
const ENDPOINT_TOKEN = `${ENDPOINT}/token`;
const ENDPOINT_CREATE = `${ENDPOINT}/login/`;
const ENDPOINT_GOOGLE = `${ENDPOINT}/google/`;
const ENDPOINT_FACEBOOK = `${ENDPOINT}/facebook/`;
const ENDPOINT_GITHUB = `${ENDPOINT}/github/`;
const ENDPOINT_MICROSOFT = `${ENDPOINT}/microsoft/`;
const ENDPOINT_SOCIAL = `${ENDPOINT}/social/`;
const ENDPOINT_LOGOUT = `${ENDPOINT}/logout/`;

const ENDPOINT_VERIFY = `${ENDPOINT_TOKEN}/verify/`;
const ENDPOINT_REFRESH = `${ENDPOINT_TOKEN}/refresh/`;

const ERROR_NO_TOKEN = 'No token in response';

export default {

  ENDPOINT,
  ENDPOINT_TOKEN,
  ENDPOINT_CREATE,
  ENDPOINT_GOOGLE,
  ENDPOINT_FACEBOOK,
  ENDPOINT_GITHUB,
  ENDPOINT_MICROSOFT,
  ENDPOINT_VERIFY,
  ENDPOINT_REFRESH,
  ENDPOINT_LOGOUT,

  SOCIAL_ENDPOINTS: {
    google: ENDPOINT_GOOGLE,
    facebook: ENDPOINT_FACEBOOK,
    github: ENDPOINT_GITHUB,
    microsoft: ENDPOINT_MICROSOFT,
  },

  REFRESH_THRESHOLD: 10000,

  async loginUser(user) {
    if (!this.isUserValid(user)) {
      return Promise.reject(requestsUtils.generateError(ERROR_NO_TOKEN));
    }
    user = { ...user, ...user.user };
    delete user.user;
    this.setupTokenByUser(user);
    browserActions.setStoredUser(user);
    return Promise.resolve(user);
  },

  async login({ username, password }) {
    return new Promise( (resolve, reject) => {
      axios({
        method: 'post',
        url: ENDPOINT_CREATE,
        data: {
          username,
          password,
        }
      })
      .then( response => { this.loginUser(response?.data).then(resolve); })
      .catch( reject );
    });
  },

  async oauth({ access_token, code, id_token }, endpoint) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: endpoint,
        data: {
          access_token,
          code,
          id_token
        },
      })
      .then( response => { this.loginUser(response?.data).then(resolve); })
      .catch(reject);
    })
  },

  async oauthСonnect({ access_token, code, id_token }, endpoint) {
    return axios({
      method: 'post',
      url: `${endpoint}connect/`,
      data: {
        access_token,
        code,
        id_token
      },
    });
  },

  async getSocial() {
    return axios({
      method: 'get',
      url: ENDPOINT_SOCIAL,
    });
  },

  async getNext(url) {
    return axios({
      method: 'get',
      url: url,
    });
  },


  async disconnectSocial({ uid }) {
    return axios({
      method: 'post',
      url: `${ENDPOINT_SOCIAL}disconnect/`,
      data: { uid },
    });
  },

  logout({ refresh_token }) {
    browserActions.removeStoredUser();
    this.removeAuthCookie();
    axios.defaults.headers.common['Authorization'] = null;
    if (refresh_token) axios({
      method: 'post',
      url: ENDPOINT_LOGOUT,
      data: {
        refresh_token
      }
    })
    .catch(Promise.resolve());
  },

  async verify(token) {
    return axios({
      method: 'post',
      url: ENDPOINT_VERIFY,
      data: {
        token,
      }
    });
  },

  async refresh({ cancelToken, invalid_access_token }) {
    return new Promise( (resolve, reject) => {
      // check for handle another browser refresh again
      let storedUser = browserActions.getStoredUser();
      let beforeExp = browserActions.extractJWTExpTimeout(storedUser.access_token);
      if (invalid_access_token !== storedUser.access_token && beforeExp > this.REFRESH_THRESHOLD) {
        this.setupTokenByUser(storedUser);
        return resolve(storedUser);
      }
      let { refresh_token } = storedUser;

      axios({
        method: 'post',
        url: ENDPOINT_REFRESH,
        data: {
          refresh_token,
        },
        cancelToken,
      })
      .then( response => {
        const { data } = response;
        if (!this.isUserValid(data)) return reject(requestsUtils.generateError(ERROR_NO_TOKEN));

        let storedUser = browserActions.getStoredUser();
        if (!storedUser) reject();
        let storedJWTInfo = browserActions.extractJWTInfo(storedUser.access_token);
        let newJWTInfo = browserActions.extractJWTInfo(data.access_token);
        // handle another tab refresh
        if (newJWTInfo.exp < storedJWTInfo.exp) {
          this.setupTokenByUser(storedUser);
          return resolve(storedUser);
        }
        this.setupTokenByUser(data);
        browserActions.setStoredUser({ ...storedUser, ...data });
        resolve(browserActions.getStoredUser());
      })
      .catch(reject);
    });
  },

  isUserValid(user) {
    if (!user) return false;
    const { access_token, refresh_token } = user;
    return !!access_token && !!refresh_token;
  },

  setupTokenByUser(user) {
    if (!this.isUserValid(user)) {
      axios.defaults.headers.common['Authorization'] = null;
      this.removeAuthCookie();
      return;
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
    this.setAuthCookie(user.access_token);
  },

  setAuthCookie(access_token) {
    let domain = config.urls.AUTH_COOKIE_DOMAIN;
    let cookie = config.keys.AUTH_COOKIE;
    let cookie_val = `${cookie}=${access_token}; SameSite=Strict; Path=/;`;
    if (domain) cookie_val += `Domain=${domain}`;
    document.cookie = cookie_val;
  },

  getAccessTokenInfo() {
    let token = axios.defaults.headers.common['Authorization'];
    if (!token) return {};
    token = token.split(' ')[1];
    return browserActions.extractJWTInfo(token);
  },

  getAccessTokenExpTimeout() {
    return browserActions.extractJWTInfoExpTimeout(this.getAccessTokenInfo());
  },

  removeAuthCookie() {
    let domain = config.urls.AUTH_COOKIE_DOMAIN;
    let cookie = config.keys.AUTH_COOKIE;
    let cookie_val = `${cookie}=; Max-Age=-1; SameSite=Strict; Path=/;`;
    if (domain) cookie_val += `Domain=${domain}`;
    document.cookie = cookie_val;
  },

};

import axios from 'axios';

const URL_API = process.env.VUE_APP_URL_API;
const BASE_REDIRECT_URL = `${location.origin}${location.pathname}`;
axios.defaults.baseURL = `${URL_API}/api/`;

export default Object.freeze({
  AUTH_COOKIE_DOMAIN: process.env.VUE_APP_AUTH_COOKIE_DOMAIN,
  URL_API,
  GOOGLE_GAPI_SCRIPT: 'https://apis.google.com/js/platform.js',
  GOOGLE_GAPI_DISCOVERY_URL: 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
  GOOGLE_GAPI_SCOPE: 'https://www.googleapis.com/auth/drive.metadata.readonly',
  FACEBOOK_OAUTH_SCRIPT: 'https://connect.facebook.net/en_US/all.js',
  GITHUB_OAUTH_URL: 'https://github.com/login/oauth/authorize',
  GITHUB_OAUTH_REDIRECT_URL: `${BASE_REDIRECT_URL}?provider=github`,
  MICROSOFT_OAUTH_REDIRECT_URL: BASE_REDIRECT_URL,
  MICROSOFT_OAUTH_AUTHORITY: 'https://login.microsoftonline.com/common',
  FEEDBACK_EMAIL: process.env.VUE_APP_FEEDBACK_EMAIL,
  MAIN_SITE_URL: 'https://gpuopen.com',
  VIEWER_URL: process.env.VUE_APP_URL_VIEWER,
  IMAGE_CDN_URL: process.env.VUE_APP_IMAGE_CDN_URL,
});

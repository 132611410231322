import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import {
  VBtn,
  VRow,
  VCol,
  VHover,
  VCard,
  VCardTitle,
  VCardText,
  VAvatar,
  VImg,
  VFadeTransition,
  VIcon,
  VSpacer,
  VAlert,
  VProgressLinear,
  VProgressCircular,
  VChip,
} from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css';

import colors from 'vuetify/lib/util/colors';
import IconCreate from '@/components/Icons/IconCreate.vue';
import IconReview from '@/components/Icons/IconReview.vue';
import IconMaterials from '@/components/Icons/IconMaterials.vue';
import IconManagement from '@/components/Icons/IconManagement.vue';
import IconZoom from '@/components/Icons/IconZoom.vue';
import IconClear from '@/components/Icons/IconClear.vue';
import IconGoogle from '@/components/Icons/IconGoogle.vue';
import IconFacebook from '@/components/Icons/IconFacebook.vue';
import IconMicrosoft from '@/components/Icons/IconMicrosoft.vue';
import IconSquare from "../components/Icons/IconSquare";
import IconList from "../components/Icons/IconList";

Vue.use(Vuetify, {
  iconfont: 'mdi',
  components: {
    VBtn,
    VRow,
    VCol,
    VHover,
    VCard,
    VCardTitle,
    VCardText,
    VAvatar,
    VImg,
    VFadeTransition,
    VIcon,
    VSpacer,
    VAlert,
    VProgressLinear,
    VProgressCircular,
    VChip,
  }
});

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      signin: 'mdi-login-variant',
      signout: 'mdi-logout-variant',
      signup: 'mdi-account-plus',
      account: 'mdi-account-circle',

      search: 'mdi-magnify',
      create: { component: IconCreate },
      add: 'mdi-playlist-plus',
      confirm: 'mdi-checkbox-marked-outline',
      save: 'mdi-floppy',
      download: 'mdi-download',
      edit: 'mdi-pencil',
      refresh: 'mdi-refresh',
      cancel: 'mdi-logout-variant mdi-flip-h',
      retry: 'mdi-reload',
      remove: 'mdi-close',
      unselect: { component: IconClear },
      clearForm: { component: IconClear },
      delete: 'mdi-delete',
      deleteFromList: 'mdi-delete-sweep',
      preview: 'mdi-eye-outline',
      back: 'mdi-arrow-left',
      forward: 'mdi-arrow-right',
      delimiter: 'mdi-circle',

      deleteMaterialFromList: 'mdi-playlist-remove',
      editMaterialsList: 'mdi-playlist-edit',
      addMaterialsList: 'mdi-playlist-plus',

      carouselNext: 'mdi-chevron-right',
      carouselPrev: 'mdi-chevron-left',

      materialPrev: 'mdi-chevron-double-left',
      materialNext: 'mdi-chevron-double-right',

      editing: 'mdi-playlist-edit',
      review: { component: IconReview, },
      published: 'mdi-playlist-check',
      unpublish: 'mdi-playlist-remove',
      rejected: 'mdi-playlist-remove',

      list: { component: IconList },
      management: { component: IconManagement, },
      collections: 'mdi-view-dashboard-outline',
      workspace: { component: IconSquare },
      materials: { component: IconMaterials, },

      help: 'mdi-help-circle-outline',
      info: 'mdi-information-outline',
      fullscreen: 'mdi-fullscreen',
      alert: 'mdi-alert',
      zoom: { component: IconZoom, },
      zoomPlus: 'mdi-plus',
      zoomMinus: 'mdi-minus',
      feedback: 'mdi-email-send-outline',
      favoriteOn: 'mdi-star',
      favoriteOff: 'mdi-star-outline',

      login: 'mdi-account',
      username: 'mdi-account',
      author: 'mdi-account',
      license: 'mdi-license',
      email: 'mdi-email-outline',
      emailSend: 'mdi-email-send-outline',
      password: 'mdi-lock-outline',
      passwordShown: 'mdi-eye-outline',
      passwordHidden: 'mdi-eye-off-outline',
      lastname: 'mdi-card-text-outline',
      firstname: 'mdi-card-text-outline',
      createdDate: 'mdi-calendar',
      alertDate: 'mdi-calendar-alert',
      acceptedDate: 'mdi-calendar-check',
      rejectedDate: 'mdi-calendar-remove',
      linkto: 'mdi-open-in-new',

      complaint: 'mdi-comment-alert-outline',
      complaintNew: 'mdi-alert-box-outline',
      complaintAccepted: 'mdi-checkbox-marked-outline',
      complaintRejected: 'mdi-close-box-outline',
      comments: 'mdi-comment-multiple-outline',

      viewer: 'mdi-rotate-3d',

      google: { component: IconGoogle },
      facebook: { component: IconFacebook },
      microsoft: { component: IconMicrosoft },

      uploaded: 'mdi-upload',
      validation: 'mdi-sync mdi-spin',
      verified: 'mdi-check',
      invalid: 'mdi-window-close'
    },
  },
  theme: {
    themes: {
      dark: {
        primary: '#ED1C24',
        secondary: '#525252',
        accent: '#374DA0',
        error: '#ED1C24',
        info: '#374DA0',
        warning: '#F26D0A',
        subsecondary: '9D9D9D',
        bg: '#191919',
        inputBg: '#191919',
        secondaryBg: '#333333',
        mainBg: '#1b1b1b',
        dialogTitleBg: '#333333',
        dialogOverlay: colors.grey.darken3,
        dialogBg: '#333333',
        cardBg: '#27282B',
        previewBg: '#292929',
      },
      light: {
        primary: '#ED1C24',
        secondary: '#525252',
        accent: '#374DA0',
        error: '#ED1C24',
        info: '#374DA0',
        warning: '#F26D0A',
        subsecondary: '9D9D9D',
        bg: '#191919',
        inputBg: '#191919',
        secondaryBg: '#333333',
        mainBg: '#1b1b1b',
        dialogTitleBg: '#333333',
        dialogOverlay: colors.grey.darken3,
        dialogBg: '#333333',
        cardBg: '#27282B',
        previewBg: '#292929',
      },
    },
    dark: true,
  },
});

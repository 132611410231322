<template>
  <v-menu
    v-bind="$props"
    v-on="$listeners"
    content-class="overflow-hidden outlined border--color--dividers flex-active"
  >
    <template #activator="scope">
      <slot
        name="activator"
        v-bind="scope"
      />
    </template>

    <app-sectioned-card
      class="d-flex flex-column overflow-hidden"
      :color="color"
      :closable="closable"
      :content-class="contentClassV"
      :no-header-divider="noHeaderDivider"
      :always-content-scroll="false"
      button-close-small
      button-close-icon-size="24"
      @click:close="$emit('input', false)"
    >
      <slot
        v-for="(_, name) in $slots"
        :name="name"
        :slot="name"
      />
    </app-sectioned-card>
  </v-menu>
</template>

<script>
  import { VMenu } from 'vuetify/lib';
  import AppSectionedCard from '@/components/AppSectionedCard';

  export default {
    name: 'AppMenu',

    extends: VMenu,

    components: {
      AppSectionedCard,
    },

    props: {
      color: {
        type: String,
      },
      contentClass: {
        type: String,
      },
      noGutters: {
        type: Boolean,
        default: false,
      },
      closable: {
        type: Boolean,
        default: false,
      },
      noHeaderDivider: {
        type: Boolean,
        default: true,
      }
    },

    computed: {
      contentClassV() {
        const { contentClass, noGutters } = this;
        const optionalClass = noGutters ? '' : 'pa-6 pt-0';
        return `${optionalClass} ${contentClass} overflow-y-auto`;
      },
    },
  }
</script>
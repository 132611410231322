import axios from 'axios';
import store from '@/store';
import browserActions from '@/utils/browserActions';
import config from '@/config';

const ENDPOINT = 'auth/user';
const CDN_URL = config.urls.IMAGE_CDN_URL;

export default {

  ENDPOINT,

  async get() {
    return axios({
      method: 'get',
      url: `${ENDPOINT}/`,
    })
    .then( (response) => {
      let storedUser = browserActions.getStoredUser();
      browserActions.setStoredUser({ ...storedUser, ...response?.data });
    });
  },

  async getLimits() {
    return axios({
      method: 'get',
      url: `${ENDPOINT}/limits/`
    })
    .then( res => Promise.resolve(res?.data) )
    .catch( err => {
      store.dispatch('notification/error', "Can't get limits your limits. Try later");
      return Promise.reject(err);
    })
  },

  async getData() {
    return this.get().then( (response) => Promise.resolve(response?.data) ).catch(Promise.reject);
  },

  async edit({ username, firstName, lastName }) {
    return axios({
      method: 'put',
      url: `${ENDPOINT}/`,
      data: {
        username,
        first_name: firstName,
        last_name: lastName,
      },
    })
    .then( (response) => {
      store.dispatch('notification/success', `User info changes successfully`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      store.dispatch('notification/error', `User info change failed`);
      return Promise.reject(error);
    });
  },

  async editAvatar({ file, onUploadProgress, cancelToken }) {
    const bodyFormData = new FormData();
    bodyFormData.append('avatar', file);
    return axios({
      method: 'patch',
      url: `${ENDPOINT}/`,
      headers: { "Content-Type": "multipart/form-data" },
      data: bodyFormData,
      onUploadProgress,
      cancelToken,
    })
    .then( response => {
      store.dispatch('notification/success', `Avatar changed successfully`);
      return Promise.resolve(response)
    })
    .catch( e => {
      if (!axios.isCancel(e)) store.dispatch('notification/error', `Avatar ${file?.name} uploading error`);
      return Promise.reject(e);
    });
  },

  getAvatarSrc(id) {
    return `${CDN_URL}/${id}` ?? require('@/assets/cat.jpg');
  },

  getThumbnailAvatarSrc(id) {
    return `${CDN_URL}/${id}` ?? require('@/assets/cat.jpg');
  },

  async patch({ username, firstName, lastName }) {
    return axios({
      method: 'patch',
      url: `${ENDPOINT}/`,
      data: {
        username,
        first_name: firstName,
        last_name: lastName,
      },
    })
    .then( (response) => {
      store.dispatch('notification/success', `User info changes successfully`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      store.dispatch('notification/error', `User info change failed`);
      return Promise.reject(error);
    });
  },

};
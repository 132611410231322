<template functional>
  <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24">
<path class="st2" d="M17.7,12c0,3.2-2.6,5.7-5.7,5.7c-3.2,0-5.7-2.6-5.7-5.7c0-3.2,2.6-5.7,5.7-5.7c0,0,0,0,0.1,0V4c0,0,0,0,0,0
    c-4.4,0-8,3.6-8,8s3.6,8,8,8c4.4,0,8-3.6,8-8H17.7z"/>
  <path class="st2" d="M18.8,8.1h-2.9V5.2c0-0.2-0.2-0.4-0.4-0.4h-1.2c-0.2,0-0.4,0.2-0.4,0.4v2.9H11c-0.2,0-0.4,0.2-0.4,0.4v1.2
    c0,0.2,0.2,0.4,0.4,0.4h2.9v2.9c0,0.2,0.2,0.4,0.4,0.4h1.2c0.2,0,0.4-0.2,0.4-0.4v-2.9h2.9c0.2,0,0.4-0.2,0.4-0.4V8.5
    C19.2,8.3,19,8.1,18.8,8.1z"/>
  <path class="st2" d="M20.5,17.2l-0.9-0.8c-0.6,1.1-1.4,2-2.4,2.7l0.7,0.8c0.6,0.6,1.8,0.3,2.4-0.3C20.8,19,21,17.7,20.5,17.2z"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconZoom',
  }
</script>

import Vue from 'vue';
import App from '@/App.vue';
import vuetify from '@/plugins/vuetify';
import store from '@/store';
import api from '@/api';
import config from '@/config';
import axios from 'axios';
import VueGtag from "vue-gtag";
import { abilitiesPlugin } from '@casl/vue';
import { ability, defineAbilityFor } from '@/abilities';
import router from '@/router';
import requestsUtils from '@/utils/requests';
import enums from '@/enums';
import { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import eventBus from '@/eventBus';
import VueMeta from 'vue-meta'
import '@/styles/index.scss';

Vue.component('RecycleScroller', RecycleScroller)

//setup library for google analytics 4
Vue.use(VueGtag, {
  config: {
    id: config.keys.GOOGLE_GA_KEY,
  },
  enabled: false,
});
Vue.use(VueMeta);

const retry = async ({ error, access_token }) => {
  if (error.config.url.startsWith(api.token.ENDPOINT_TOKEN)) return Promise.reject(error)
  if (!error?.config) return Promise.reject(error);
  error.config.headers.Authorization = access_token ? `Bearer ${access_token}` : undefined;
  error.config.wasRetried = true;
  return axios.request(error.config);
}

const logoutTokenExpired = async (error) => {
  if (store.getters['account/wasLoggedOutExpired']) return retry({ error })
  store.commit('account/setLoggedOutExpired', true)
  store.dispatch('notification/warning', 'Session has expired. You are logged out.');
  return store.dispatch('account/logoutSilent')
      .then( () => {
        if (router.currentRoute.name !== 'materials:all') router.push({ name: 'materials:all' })
        return retry({ error })
      })
}

// requests retry on access token expired
axios.interceptors.response.use(
  response => response,
  error => {
    const isAuthError = error?.response?.status === 401;
    if (axios.isCancel(error)) return Promise.reject(error);
    if (!error.config?.url) return logoutTokenExpired();
    if (isAuthError && error.config?.url === api.token.ENDPOINT_REFRESH) return logoutTokenExpired();
    // no sense to retry verify or logout
    if ([ api.token.ENDPOINT_VERIFY, api.token.ENDPOINT_LOGOUT ].includes(error.config?.url)) return Promise.reject(error);

    // hadle error for ususal request (for example get materials)
    if (
      isAuthError &&
      error.config &&
      !error.config.wasRetried &&
      !error.config.url.startsWith(api.token.ENDPOINT_TOKEN)
    ) {
      // if already logged out maybe caused by expiration
      if (!store.getters['account/isLoggedIn']) return logoutTokenExpired(error);
      else return store.dispatch('account/refresh')
          .then( ({ access_token=undefined }) => retry({ error, access_token }) )
          .catch( () => logoutTokenExpired(error) );
    }

    requestsUtils.getErrorMessages(error).forEach( m => store.dispatch('notification/error', m) );
    return Promise.reject(error);
  }
);


// define rules for user that already stored in vuex before App.vue initialized
let rules = defineAbilityFor(store.state.account.user);
ability.update(rules);
Vue.use(abilitiesPlugin, ability);

Vue.config.productionTip = true;

Vue.prototype.$bus = eventBus;
Vue.prototype.$api = api;
Vue.prototype.$enums = enums;
Vue.prototype.$config = config;

import AppButton from '@/components/AppButton';
import AppTextField from '@/components/AppTextField';
import AppTextArea from '@/components/AppTextArea';
import AppAutocomplete from '@/components/AppAutocomplete';
import AppSelect from '@/components/AppSelect';
import AppCard from '@/components/AppCard';

Vue.component('app-button', AppButton);
Vue.component('app-text-field', AppTextField);
Vue.component('app-textarea', AppTextArea);
Vue.component('app-autocomplete', AppAutocomplete);
Vue.component('app-select', AppSelect);
Vue.component('app-card', AppCard);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');

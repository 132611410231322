<template>
  <v-textarea
    v-bind="$props"
    v-on="$listeners"
  >
    <slot
      v-for="(_, name) in $slots"
      :name="name"
      :slot="name"
    />
    <template
      v-for="(_, slot) of $scopedSlots"
      slot-scope="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </v-textarea>
</template>

<script>
  import {
    VTextarea,
  } from 'vuetify/lib';

  export default {
    name: 'AppTextArea',

    extends: VTextarea,

    props: {
      backgroundColor: {
        type: String,
        default: 'inputBg',
      },
      dense: {
        type: Boolean,
        default: true,
      },
      flat: {
        type: Boolean,
        default: true,
      },
      solo: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>
<template functional>
  <svg height="24" width="24" class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <polyline points="2,5 2,7 14,7 14,5 2,5 "/>
    <polyline points="2,9 2,11 11,11 11,9 2,9 "/>
    <rect x="2" y="13" width="5" height="2"/>
    <path d="M17.9,8.8c-0.3,0-0.6,0.1-0.8,0.4l-6.7,6.7c-0.5,0.5-0.5,1.2,0,1.7l1.5,1.5h4.6l5.2-5.2c0.5-0.5,0.5-1.2,0-1.7l-2.9-2.9
      C18.5,8.9,18.2,8.8,17.9,8.8"/>
    <polyline points="19,17.8 17.8,19 22,19 22,17.8 "/>
  </svg>
</template>

<script>
  export default {
    name: 'IconСlear',
  }
</script>

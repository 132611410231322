<!--
In certain versions of Chrome with a white theme, the colors of the substrates were broken.
To solve this problem, a wrapper component for v-card was created, which strictly specifies the color of the substrates.
-->
<template>
<v-card
    v-bind="$props"
    v-on="$listeners">
  <slot
      v-for="(_, name) in $slots"
      :name="name"
      :slot="name"
  />
  <template
      v-for="(_, slot) of $scopedSlots"
      slot-scope="scope"
  >
    <slot
        :name="slot"
        v-bind="scope"
    />
  </template>
</v-card>
</template>

<script>

import {
  VCard
} from 'vuetify/lib';

export default {

  extends: VCard,

  name: "AppCard",

  props: {
    color: {
      type: String,
      default: 'cardBg',
    }
  }
}
</script>
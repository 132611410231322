<template>
  <app-menu
    v-if="$vuetify.breakpoint.smAndUp"
    v-model="menu"
    max-width="300"
    min-width="300"
    bottom
    no-gutters
    content-slot-class="pa-4"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on">
        <v-avatar
          :size="size"
          :color="avatarColor"
          class="cursor-pointer d-flex flex-row align-center"
          v-on="on"
        >
          <v-img
            v-if="isLoggedIn"
            :src="accountImageSrc"
            class="fill-height d-flex flex-row align-center justify-center"
          >
            <template #placeholder>
              <div class="fill-height d-flex flex-row align-center justify-center display-3">
                {{ username[0] }}
              </div>
            </template>
          </v-img>

          <v-icon
            v-else
            :size="size"
            class="ma-auto"
          >
            mdi-account-circle
          </v-icon>
        </v-avatar>
      </slot>
    </template>
    <menu-avatar-content
      :avatar-color="avatarColor"
      :account-info="{ accountImageSrc, isLoggedIn, actualName, actualUsername }"
    />
  </app-menu>
  <menu-avatar-content
    v-else
    :avatar-color="avatarColor"
    :account-info="{ accountImageSrc, isLoggedIn, actualName, actualUsername }"
  />
</template>

<script>
  import { mapGetters } from 'vuex';
  import AppMenu from '@/components/AppMenu';
  import MenuAvatarContent from "./Forms/MenuAvatarContent";

  export default {
    name: 'MenuAvatar',

    components: {
      MenuAvatarContent,
      AppMenu,
    },

    props: {
      size: {
        type: [Number, String],
        default: 40,
      },
      iconSize: {
        type: [Number, String],
      },
    },

    data() {
      return {
        menu: false,
      };
    },

    computed: {
      ...mapGetters({
        isLoggedIn: 'account/isLoggedIn',
        username: 'account/username',
        firstName: 'account/firstName',
        lastName: 'account/lastName',
        avatarThumbnail: 'account/avatarThumbnail',
      }),

      accountImageSrc() {
        return this.$api.user.getAvatarSrc(this.avatarThumbnail);
      },

      actualUsername() {
        return this.isLoggedIn ? this.username : 'Guest';
      },

      actualName() {
        let { firstName, lastName, isLoggedIn } = this;
        firstName ??= '';
        lastName ??= '';
        return isLoggedIn ? `${firstName} ${lastName}` : '';
      },

      avatarColor() {
        return this.isLoggedIn ? 'bg' : 'bg';
      },
    }
  }
</script>
import { defineAbility, Ability } from '@casl/ability';
import enums from '@/enums';


export const ability = new Ability([]);
const {
  Status,
  StatusCollection,
  StatusComplaint,
  Entity
} = enums;

const {
  ACCOUNT,
  PASSWORD,
  CATEGORY,
  COLLECTION,
  COMMENT,
  COMPLAINT,
  MANAGEMENT,
  MATERIAL,
  RENDER,
  TAG,
  PACKAGE
} = Entity;

export function defineAbilityFor(user) {
  return defineAbility( (can, cannot) => {

    can('read', MATERIAL, { status: Status.PUBLISHED.key });
    can('read', COLLECTION, { status: StatusCollection.PUBLIC.key });
    can('create_complaint', MATERIAL, { status: Status.PUBLISHED.key });
    can('open_viewer', MATERIAL, { viewer_enabled: true });

    let groups = user?.groups ?? [];

    if (user) {
      can('create', [RENDER, PACKAGE])
      can(['update', 'delete'], [RENDER, PACKAGE], { author: user.username });
      cannot(['update', 'delete'], [RENDER, PACKAGE], { status: Status.PUBLISHED.key });

      can('read_my', MATERIAL);
      can('create', [MATERIAL, TAG]);
      can('unmake_favorite', MATERIAL, { status: Status.PUBLISHED.key, favorite: true });
      can('make_favorite', MATERIAL, { status: Status.PUBLISHED.key, favorite: false });
      can(['update', 'delete', 'comment'], MATERIAL, { author: user.username });
      can('revert_from_review', MATERIAL, { author: user.username , status: Status.REVIEW.key });
      can('send_to_review', MATERIAL, { author: user.username , status: Status.EDITING.key });
      cannot(['update', 'delete'], MATERIAL, { status: Status.PUBLISHED.key });

      can(['update', 'delete'], COLLECTION, { author: user.username, status: StatusCollection.PRIVATE.key });
      can('read_my', COLLECTION);
      can('create_my', COLLECTION);

      can(['create', 'read_my'], COMPLAINT);
      can('delete', COMPLAINT, { status: StatusComplaint.NEW.key, author: user.username });

      can(['read', 'create'], COMMENT);
      can(['update', 'delete'], COMMENT, { author: user.username });

      can('update', PASSWORD);
      can('read_my', ACCOUNT);

    }

    if (groups.includes(enums.Roles.ADMIN) || groups.includes(enums.Roles.MODERATOR)) {
      can(['read', 'create', 'update', 'delete'], [MATERIAL, PACKAGE, RENDER, CATEGORY, TAG, COLLECTION, ACCOUNT]);
      can(['read', 'create', 'delete'], COMMENT);
      can('publish', COLLECTION, { status: { $ne: enums.StatusCollection.PUBLIC.key } });
      can('unpublish', COLLECTION, { status: enums.StatusCollection.PUBLIC.key });
      can('publish', MATERIAL, { status: { $ne: enums.Status.PUBLISHED.key  } });
      can('unpublish', MATERIAL, { status: enums.Status.PUBLISHED.key });
      can('revert_from_review', MATERIAL, { status: enums.Status.REVIEW.key });
      can('comment', MATERIAL, { status: enums.Status.REVIEW.key });
      can('enable_viewer', MATERIAL, { viewer_enabled: false });
      can('disable_viewer', MATERIAL, { viewer_enabled: true });
      can('read', MANAGEMENT);
      can(['read', 'delete'], COMPLAINT);
      can(['accept', 'reject'], COMPLAINT, { status: StatusComplaint.NEW.key });
    }

  });
}

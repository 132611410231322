const IS_MOBILE = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

export default {
  download(data, filename, type) {
    let file = new Blob([data], {type: type});

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, filename);
    } else {

      let a = document.createElement("a");
      let url = URL.createObjectURL(file);

      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  },

  downloadByUrl(url, filename) {
    let a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
  },

  addScript(src){
    let script = document.createElement('script');
    script.src = src;
    document.head.appendChild(script);
    return script;
  },

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  },

  preloadImage(url) {
    let img = new Image();
    img.src = url;
  },

  scrollToBottomIn(element) {
    if (!element) return;
    element.scrollTop = element.scrollHeight;
  },

  getStoredItemOffset() {
    return localStorage.getItem('itemOffset');
  },

  setStoredItemOffset(value) {
    localStorage.setItem('itemOffset', value);
  },

  getStoredUser() {
    return JSON.parse(localStorage.getItem('user'));
  },

  setStoredUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  },

  removeStoredUser() {
    localStorage.removeItem('user');
  },

  extractJWTInfo(token) {
    if (!token) return {};
    return JSON.parse(atob(token.split('.')[1]));
  },

  extractJWTInfoExpTimeout(jwtInfo) {
    if (!jwtInfo.exp) return -1;
    return jwtInfo.exp * 1000 - new Date().getTime();
  },

  extractJWTExpTimeout(token) {
    return this.extractJWTInfoExpTimeout(this.extractJWTInfo(token));
  },

  is3DSupported() {
    let el = document.createElement('canvas')
    let val = el.getContext('webgl2'); // if we can get webgl2 context - 3D mode is supported
    el.remove();
    return !!val;
  },

  toggleFullscreen(refElement) {
    if (!document.fullscreenElement) {
      let element = refElement._isVue ? refElement.$el : refElement;
      element.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  },

  IS_MOBILE
};

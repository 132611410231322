<template>
  <p
    :class="{ 'subsecondary--text': isSecondaryText }"
    class="ma-0 text-wrap"
    v-html="contentToHtml"
  />
</template>

<script>
import linkifyHtml from "linkifyjs/lib/linkify-html";
import marked from 'marked/marked.min';
import * as sanitizeHtml from 'sanitize-html';

export default {
  name: "FormattedText",

  props: {
    text: {
      type: String,
      default: ''
    },
    isSecondaryText: {
      type: Boolean,
      default: true
    },
    contentType: {
      type: String,
      default: 'text/plain'
    },
    replaceable: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    contentToHtml() {
      // you can find default allowed tag set in documentation https://www.npmjs.com/package/sanitize-html
      return Object
        .entries(this.replaceable)
        .reduce(
          (p, [k, v]) => p.replaceAll(k, v),
          sanitizeHtml({
            'text/plain': () => linkifyHtml(this.text.replaceAll('\n', '<br>')),
            'text/markdown': () => marked(this.text),
            'text/html': () => this.text
          }[this.contentType]())
        )
    }
  }
}
</script>
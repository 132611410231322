const MS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const MS_IN_TEN_DAYS = 10 * HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MS_IN_SECOND;

const BYTES_IN_KB = 1024;
const KB_IN_MB = 1024;
const BYTES_IN_10MB = KB_IN_MB * BYTES_IN_KB * 10;
const BYTES_IN_700MB = KB_IN_MB * BYTES_IN_KB * 700;

export default Object.freeze({
  USERNAME_LENGTH_MIN: 3,
  USERNAME_LENGTH_MAX: 30,
  FIRST_NAME_LENGTH_MAX: 30,
  LAST_NAME_LENGTH_MAX: 30,
  PASSWORD_LENGTH_MIN: 8,
  MATERIAL_TITLE_LENGTH_MAX: 30,
  MATERIAL_NEW_MAX_DIFF_MS: MS_IN_TEN_DAYS,
  CATEGORY_TITLE_LENGTH_MAX: 30,
  COLLECTION_TITLE_LENGTH_MAX: 30,
  EMAIL_LENGTH_MAX: 30,
  MATERIAL_DESCRIPTION_LENGTH_MAX: 500,
  COLLECTION_DESCRIPTION_LENGTH_MAX: 500,
  COMPLAINT_MESSAGE_LENGTH_MAX: 500,
  COMMENT_MESSAGE_LENGTH_MAX: 500,
  TAG_TITLE_LENGTH_MAX: 30,
  PACKAGE_FILE_SIZE_MAX: BYTES_IN_700MB,
  RENDER_FILE_SIZE_MAX: BYTES_IN_10MB,
  RENDERS_AMOUNT_MAX: 5,
  MATERIALS_LOADING_LIMIT: 20,
  MATERIALS_PRELOADING_CHUNKS: 2,
  CATEGORIES_LOADING_LIMIT: 20,
  VERIFICATION_COMPLETE_MESSAGE: "Verification is already completed.",
  AMD_USER: "AMD",
});
import axios from 'axios';
import urlsUtils from '@/utils/urls';
import store from '@/store';

const ENDPOINT = 'complaints';
const ENTITY_TITLE = 'Complaint';
const ENTITY_TITLE_MANY = 'Complaints';

export default {

  ENDPOINT,

  async getAll(filter) {
    return axios({
      method: 'get',
      url: urlsUtils.constructQueryUrl(`${ENDPOINT}/`, filter),
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error", `${ENTITY_TITLE_MANY} loading error`);
      return Promise.reject(error);
    });
  },

  async getAuthors() {
    return axios({
      method: 'get',
      url: urlsUtils.constructQueryUrl(`${ENDPOINT}/authors/`),
    })
      .then( (response) => Promise.resolve(response.data?.authors) )
      .catch( error => {
        if (!axios.isCancel(error)) store.dispatch("notification/error", `${ENTITY_TITLE_MANY} authors loading error`);
        return Promise.reject(error);
      });
  },

  async getAllForUser(filter) {
    return axios({
      method: 'get',
      url: urlsUtils.constructQueryUrl(`${ENDPOINT}/workspace/`, filter),
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error", `User ${ENTITY_TITLE_MANY} loading error`);
      return Promise.reject(error);
    });
  },

  async getNext(url) {
    return axios({
      method: 'get',
      url: url,
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error", `${ENTITY_TITLE_MANY} loading error`);
      return Promise.reject(error);
    });
  },

  async get(idOrIds) {
    if (Array.isArray(idOrIds)) return Promise.all( idOrIds.map(this.get) );

    return axios({
      method: 'get',
      url: `${ENDPOINT}/${idOrIds}/`,
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error", `${ENTITY_TITLE} ${idOrIds} loading error`);
      return Promise.reject(error);
    });
  },

  async getData(idOrIds) {
    return this.get(idOrIds)
    .then( result => {
      if (Array.isArray(result)) return Promise.resolve(result.map( r => r.data));

      return Promise.resolve(result.data);
    })
    .catch( Promise.reject );
  },

  async count(filter) {
    return axios({
      method: 'get',
      url: urlsUtils.constructQueryUrl(`${ENDPOINT}/count`, filter),
    })
  },

  async add({
    message,
    material,
  }) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/`,
      data: {
        message,
        material,
      }
    })
    .then( (response) => {
      store.dispatch('notification/success', `${ENTITY_TITLE} created`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `${ENTITY_TITLE} creation error`);
      return Promise.reject(error);
    });
  },

  async edit(id, {
    message,
    material,
  }) {
    return axios({
      method: 'put',
      url: `${ENDPOINT}/${id}/`,
      data: {
        message,
        material,
      }
    })
    .then( (response) => {
      store.dispatch('notification/success', `${ENTITY_TITLE} saved`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `${ENTITY_TITLE} editing error`);
      return Promise.reject(error);
    });
  },

  async patch(id, editData) {
    return axios({
      method: 'patch',
      url: `${ENDPOINT}/${id}/`,
      data: editData,
    })
    .then( (response) => {
      store.dispatch('notification/success', `${ENTITY_TITLE} saved`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `${ENTITY_TITLE} editing error`);
      return Promise.reject(error);
    });
  },

  async delete(id) {
    return axios({
      method: 'delete',
      url: `${ENDPOINT}/${id}/`,
    })
    .then( (response) => {
      store.dispatch('notification/success', `${ENTITY_TITLE} ${id} deleted`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `${ENTITY_TITLE} ${id} deletion error`);
      return Promise.reject(error);
    });
  },

  async accept(id) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/${id}/accept/`,
    })
    .then( (response) => {
      store.dispatch('notification/success', `${ENTITY_TITLE} ${id} accepted`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `${ENTITY_TITLE} ${id} accept error`);
      return Promise.reject(error);
    });
  },

  async reject(id) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/${id}/reject/`,
    })
    .then( (response) => {
      store.dispatch('notification/success', `${ENTITY_TITLE} ${id} rejected`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `${ENTITY_TITLE} ${id} reject error`);
      return Promise.reject(error);
    });
  },
};

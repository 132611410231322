<template>
  <v-row class="ma-0 pa-0" justify="center">
    <app-button
      @click="mobileMenuCall"
      v-if="isMobileMenuBtnVisible"
      main-icon="$vuetify.icons.list"
      icon
      :class="mobileMenuOpen ? 'primary--text': ''"
      hover-class="primary--text"
      content-class="text-uppercase font-weight-black"
      class="ma-1"
    >
      <span class="d-block" style="line-height: 14px; max-height: 14px;" >
      Categories
    </span>
    </app-button>
    <app-button
      v-for="{ key, title, to, icon } in routes"
      :key="key"
      :prepend-icon="iconed ? undefined : icon"
      :main-icon="iconed ? icon : undefined"
      :icon="iconed"
      :active-class="!mobileMenuOpen ? 'primary--text': ''"
      hover-class="primary--text"
      content-class="text-uppercase font-weight-black"
      class="ma-1"
      :to="to"
      @click="notifyThatNotAvailable(to)"
    >
    <span class="d-block" style="line-height: 14px; max-height: 14px;" >
      {{ title }}
    </span>
    </app-button>
  </v-row>
</template>

<script>
  import abilitiesArrayFilter from '@/mixins/abilitiesArrayFilter';
  import AppButton from '@/components/AppButton';

  export default {
    name: 'MaterialsTypeNavigation',

    mixins: [
      abilitiesArrayFilter,
    ],

    components: {
      AppButton
    },

    props: {
      iconed: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        routesRaw: [
          {
            key: 1,
            title: 'Materials',
            icon: '$vuetify.icons.materials',
            to: { name: 'materials:all' },
          },
          {
            key: 2,
            title: 'Collections',
            icon: '$vuetify.icons.collections',
            to: { name: 'materials:collections' },
          },
          {
            key: 3,
            title: 'Workspace',
            icon: '$vuetify.icons.workspace',
            to: { name: 'workspace' },
            ability: ['read_my', 'Material'],
            disable: true,
          },
        ],
        mobileMenuOpen: false
      }
    },

    computed: {
      routes() {
        return this.filterByAbilities(this.routesRaw);
      },

      isMobileMenuBtnVisible() {
        return this.$vuetify.breakpoint.xsOnly && this.$route.name === 'materials:all';
      },
    },

    mounted() {
      this.$bus.$on('navigation:is-open', this.changeNavigationState);
    },

    beforeDestroy() {
      this.$bus.$off('navigation:is-open', this.changeNavigationState)
    },

    methods: {

      changeNavigationState(state) {
        this.mobileMenuOpen = state;
      },

      notifyThatNotAvailable(to) {
        if (to === undefined) this.$store.dispatch('notification/warning', 'Page is not available now');
      },

      mobileMenuCall() {
        this.$bus.$emit('navigation:open');
      }
    },
  }
</script>
<template>
  <v-app
    id="app"
    dark
    class="overflow-x-auto"
  >
    <notifications/>

    <navigation class="flex-shrink-0 flex-grow-0" v-if="isNavigation"/>

    <cookies-accept v-if="showCookies"/>

    <v-main class="overflow-auto flex-grow-1 fill-height">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  import { mapState } from 'vuex';
  import { defineAbilityFor } from '@/abilities';

  import Navigation from "@/components/Navigation";
  const Notifications = () => import('@/components/Notifications');
  const CookiesAccept = () => import('@/components/CookiesAccept');

  export default {
    name: 'App',

    metaInfo() {
      return {
        meta: [
          { property: 'og:locale', content: 'en_US' },
          { property: 'og:type', content: 'website' },
          { property: 'og:site_name', content: 'MaterialX Library' }
        ]
      }
    },

    components: {
      Notifications,
      Navigation,
      CookiesAccept,
    },

    data() {
      return {
        wasCookiesPolicyChosen: true,
        refreshTimeout: null,
      };
    },

    computed: {
      ...mapState({
        user: state => state.account.user,
      }),

      isNavigation() {
        return !this.$route.meta.noNavigation;
      },

      showCookies() {
        return !(this.wasCookiesPolicyChosen || this.$route.name === 'policies-cookies')
      },
    },

    watch: {
      user: {
        immediate: true,
        handler(v, old) {
          this.defineAbility();
          if (!v) this.clearTimeoutRefresh();
          else if (!old) this.setTimeoutRefresh();
        },
      },

      isNavigation(v) {
        this.$root.$emit('show:navigation', v);
      },
    },

    created() {
      this.defineAbility();
      this.acceptCookies();
      this.$bus.$on('accept:cookies', this.acceptCookies);
      this.$bus.$on('decline:cookies', this.acceptCookies);
      this.$bus.$on('logout', this.onLogout);
      window.addEventListener('focus', this.onAppFocus);
      window.addEventListener('blur', this.onAppBlur);
    },

    beforeDestroy() {
      this.$bus.$off('accept:cookies', this.acceptCookies);
      this.$bus.$off('decline:cookies', this.acceptCookies)
      this.$bus.$off('logout', this.onLogout);
      window.removeEventListener('focus', this.onAppFocus);
      window.removeEventListener('blur', this.onAppBlur);
      this.clearTimeoutRefresh();
    },

    methods: {
      defineAbility() {
        this.$ability.update(defineAbilityFor(this.user).rules);
      },

      acceptCookies() {
        let isCookiesAccepted = localStorage.getItem('accept_cookies');

        if (!isCookiesAccepted) {
          this.wasCookiesPolicyChosen = false;
          return;
        }

        try {
          isCookiesAccepted = JSON.parse(isCookiesAccepted);
          this.wasCookiesPolicyChosen = true;
          if (isCookiesAccepted) {
            this.$gtag.optIn();
          }
        } catch {
          localStorage.removeItem('accept_cookies');
          this.wasCookiesPolicyChosen = false;
          console.log('Cant recognize cookie accept_cookies');
        }
      },

      onAppBlur() {
        this.clearTimeoutRefresh();
      },

      onAppFocus() {
        if (!this.user) return;
        this.refreshTimeout = null;
        this.setTimeoutRefresh();
      },

      refreshToken() {
        this.$store.dispatch('account/refreshIfNotRefreshing');
      },

      setTimeoutRefresh() {
        if (this.refreshTimeout === -1) return;
        const threshold = this.$api.token.REFRESH_THRESHOLD;
        let expTimeout
        try {
          expTimeout = this.$api.token.getAccessTokenExpTimeout();
        } catch {
          return;
        }
        if (expTimeout === -1) return;
        if (expTimeout <= threshold) return this.$store.dispatch('account/refreshIfNotRefreshing').then(this.setTimeoutRefresh);
        let timeout = expTimeout - threshold;
        this.refreshTimeout = setTimeout(this.setTimeoutRefresh, timeout);
      },

      clearTimeoutRefresh() {
        if (!this.refreshTimeout) return;
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = -1;
      },

      onLogout() {
        if (this.$route.name !== 'main') this.$router.push({ name: 'main' });
      },
    },
  };
</script>

import urls from './modules/urls';
import restrictions from './modules/restrictions';
import keys from './modules/keys';
import common from './modules/common';

export default Object.freeze({
  urls,
  restrictions,
  keys,
  common,
});
export default {
  constructQueryArgs(argsObj) {
    if (!argsObj) return undefined;

    return Object.entries(argsObj)
    .filter( ([key, value]) => key && value != undefined && value != null )
    .map( ([key, value]) =>
      Array.isArray(value)
      ? value.map( v => `${key}=${v}`).join("&")
      : `${key}=${value}`
    )
    .join("&")
  },

  constructQueryUrl(url, argsObj) {
    if (!argsObj) return url;
    let args = this.constructQueryArgs(argsObj);
    return args ? `${url}?${args}` : url;
  },
};

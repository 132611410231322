<template>
  <div>
    <v-row
      align="center"
      no-gutters
      class="pa-1 pb-0"
    >
      <v-avatar
        :color="avatarColor"
        size="100"
        class="ma-4"
      >
        <v-img
          v-if="accountInfo.isLoggedIn"
          :src="accountInfo.accountImageSrc"
        >
        </v-img>

        <v-icon
          v-else
          class="ma-auto"
          size="100"
        >
          mdi-account-circle
        </v-icon>
      </v-avatar>

      <v-col
        justify="center"
        align="start"
        class="pa-2"
        :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
      >
        <div v-if="accountInfo.actualName">{{ accountInfo.actualName }}</div>
        <div>{{ accountInfo.actualUsername }}</div>
      </v-col>
    </v-row>

    <v-row class="mt-2 pa-4 pt-0" no-gutters>
      <v-col>
        <app-button
          v-if="accountInfo.isLoggedIn"
          :to="{ name: 'account:me' }"
          block
          outlined
          color="success"
          prepend-icon="$vuetify.icon.account"
          class="mb-4"
        >
          Account
        </app-button>

        <app-button
          v-if="accountInfo.isLoggedIn"
          block
          color="primary"
          prepend-icon="$vuetify.icon.signout"
          @click="onSignOutClick"
        >
          Sign Out
        </app-button>

        <template v-else>
          <app-button
            :to="{ name: 'login' }"
            block
            outlined
            color="success"
            prepend-icon="$vuetify.icon.signin"
          >
            Sign In
          </app-button>

          <div class="text-center py-1">
            OR
          </div>

          <app-button
            :to="{ name: 'registration' }"
            block
            outlined
            color="primary"
            prepend-icon="$vuetify.icon.signup"
          >
            Sign Up
          </app-button>
        </template>

      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "MenuAvatarContent",

  props: {
    avatarColor: { type: String },
    accountInfo: { type: Object }
  },

  methods: {
    onSignOutClick() {
      this.$store.dispatch('account/logout');
    }
  }
}
</script>

<style scoped>

</style>
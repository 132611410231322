<template functional>
  <svg class="svg-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" height="24" width="24">
  <path d="M12,16.5c1.2,0,2.3,1,2.3,2.3S13.2,21,12,21s-2.3-1-2.3-2.3S10.8,16.5,12,16.5 M12,9.8c1.2,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3
    s-2.3-1-2.3-2.3S10.8,9.8,12,9.8 M12,3c1.2,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3s-2.3-1-2.3-2.3S10.8,3,12,3 M5.3,16.5
    c1.2,0,2.3,1,2.3,2.3S6.5,21,5.3,21S3,20,3,18.8S4,16.5,5.3,16.5 M5.3,9.8c1.2,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3S3,13.2,3,12
    S4,9.8,5.3,9.8 M5.3,3c1.2,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3S3,6.5,3,5.3S4,3,5.3,3 M18.8,16.5c1.2,0,2.3,1,2.3,2.3S20,21,18.8,21
    s-2.3-1-2.3-2.3S17.5,16.5,18.8,16.5 M18.8,9.8c1.2,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3s-2.3-1-2.3-2.3S17.5,9.8,18.8,9.8 M18.8,3
    C20,3,21,4,21,5.3s-1,2.3-2.3,2.3s-2.3-1-2.3-2.3S17.5,3,18.8,3z"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconMaterials',
  }
</script>
import axios from 'axios';
import browserActions from "@/utils/browserActions";
import urlsUtils from '@/utils/urls';
import store from '@/store';
import config from '@/config';

const ENDPOINT = 'packages';

export default {

  ENDPOINT,

  parseFileNameByUrl(url) {
    return url.split('/').slice(-1)[0];
  },

  addTitleToItems(items) {
    if (!Array.isArray(items)) {
      items = [ items ];
    }

    items.forEach(item => {
      item.title = this.parseFileNameByUrl(item.file);
      item.name = item.label ?? item.title;
    });
  },

  async getAll(filter) {
    return axios({
      method: 'get',
      url: urlsUtils.constructQueryUrl(`${ENDPOINT}/`, filter),
    })
    .then( response => {
      this.addTitleToItems(response.data.results);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      store.dispatch("notification/error", "Packages loading error");
      return Promise.reject(error);
    });
  },

  async getNext(url) {
    return axios({
      method: 'get',
      url: url,
    })
    .then( response => {
      this.addTitleToItems(response.data.results);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      store.dispatch("notification/error", "Packages loading error");
      return Promise.reject(error);
    });
  },

  async get(idOrIds) {
    if (Array.isArray(idOrIds)) return Promise.all( idOrIds.map(this.get) );

    return axios({
      method: 'get',
      url: `${ENDPOINT}/${idOrIds}/`,
    })
    .then( response => {
      this.addTitleToItems(response.data);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      store.dispatch("notification/error",  `Package ${idOrIds} loading error`);
      return Promise.reject(error);
    });
  },

  getPackageSrc(id) {
    // to get full url
    return `${axios.defaults.baseURL}${ENDPOINT}/${id}/download/`;
  },

  async getData(idOrIds) {
    return this.get(idOrIds)
    .then( result => {
      if (Array.isArray(result)) return Promise.resolve(result.map( r => r.data));

      return Promise.resolve(result.data);
    })
  },

  async count() {
    return axios({
      method: 'get',
      url: `${ENDPOINT}/count/`,
    })
  },

  async getUnattached() {
    return axios({
      method: 'get',
      url: `${ENDPOINT}/cleanup/`,
    })
    .then( res => Promise.resolve(res?.data) )
    .catch( Promise.reject );
  },

  async add({ file, onUploadProgress, cancelToken }) {
    if (file.size > config.restrictions.PACKAGE_FILE_SIZE_MAX) {
      store.dispatch('notification/error', `Size of file ${file.name} overflows restriction`);
      return Promise.reject();
    }

    let bodyFormData = new FormData();
    bodyFormData.append('file', file);

    return axios({
      method: 'post',
      url: `${ENDPOINT}/`,
      headers: { "Content-Type": "multipart/form-data" },
      data: bodyFormData,
      onUploadProgress,
      cancelToken,
    })
    .then( response => {
      this.addTitleToItems(response.data);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Package ${file?.name} uploading error`);
      return Promise.reject(error);
    });
  },

  async edit(id, file) {
    if (file.size > config.restrictions.PACKAGE_FILE_SIZE_MAX) {
      store.dispatch('notification/error', `Size of file ${file.name} overflows restriction`);
      return Promise.reject();
    }

    let bodyFormData = new FormData();
    bodyFormData.append('file', file);

    return axios({
      method: 'put',
      url: `${ENDPOINT}/${id}/`,
      headers: { "Content-Type": "multipart/form-data" },
      data: bodyFormData,
    })
    .then( response => {
      this.addTitleToItems(response.data);
      store.dispatch('notification/success', `Package ${response.data?.file} saved`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Package ${id} editing error`);
      return Promise.reject(error);
    });
  },

  async patch(id, editData) {
    return axios({
      method: 'patch',
      url: `${ENDPOINT}/${id}/`,
      data: editData,
    })
    .then( response => {
      this.addTitleToItems(response.data);
      store.dispatch('notification/success', `Package ${response.data?.title} saved`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Package ${id} editing error`);
      return Promise.reject(error);
    });
  },

  async download(id) {
    return new Promise( resolve => {
      browserActions.downloadByUrl(this.getPackageSrc(id));
      resolve();
    });
  },

  async delete(id) {
    return axios({
      method: 'delete',
      url: `${ENDPOINT}/${id}/`,
    })
    .then( (response) => {
      store.dispatch('notification/success', `Package ${id} deleted`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Package ${id} deletion error`);
      return Promise.reject(error);
    });
  },

  async deleteUnattached() {
    return axios({
      method: 'delete',
      url: `${ENDPOINT}/cleanup/`,
    })
    .then( res => {
      store.dispatch('notification/success', `Successfully clean packages`);
      return Promise.resolve(res?.data)
    })
    .catch( Promise.reject );
  },
};
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '/auth',
    name: 'auth',
    redirect: { name: 'login' },
    component: () => import('@/views/Auth/AuthView'),
    meta: {
      title: 'Auth | Material Library',
      noNavigation: true,
    },
    children: [
      {
        path: 'login',
        name: 'login',
        components: {
          content: () => import('@/views/Auth/Login'),
        },
        meta: {
          title: 'Login | Material Library',
          noNavigation: true,
        },
      },
      {
        path: 'password-change',
        name: 'password-change',
        components: {
          content: () => import('@/views/Auth/ChangePassword'),
        },
        meta: {
          title: 'Change password | Material Library',
          noNavigation: true,
        },
      },
      {
        path: 'password-reset',
        name: 'password-reset',
        components: {
          content: () => import('@/views/Auth/ResetPassword'),
        },
        meta: {
          title: 'Reset password | Material Library',
          noNavigation: true,
        },
      },
      {
        path: 'password-reset/confirm/:uid/:token',
        name: 'password-reset-confirm',
        props: {
          content: (route) => ({
            uid: route.params.uid,
            token: route.params.token,
          }),
        },
        components: {
          content: () => import('@/views/Auth/ResetPasswordConfirm'),
        },
        meta: {
          title: 'Confirm password reset | Material Library',
          noNavigation: true,
        },
      },
      {
        path: 'registration',
        name: 'registration',
        props: {
          content: (route) => ({
            token: route.params.token,
          }),
        },
        components: {
          content: () => import('@/views/Auth/Registration'),
        },
        meta: {
          title: 'Registration | Material Library',
          noNavigation: true,
        },
      },
      {
        path: 'registration/confirm-waiting/:username',
        name: 'registration-confirm-waiting',
        props: {
          content: (route) => ({
            username: route.params.username,
          }),
        },
        components: {
          content: () => import('@/views/Auth/RegistrationConfirmWaiting'),
        },
        meta: {
          title: 'Confirm waiting registration | Material Library',
          noNavigation: true,
        },
      },
      {
        path: 'registration/confirm-resend',
        name: 'registration-confirm-resend',
        components: {
          content: () => import('@/views/Auth/RegistrationResend'),
        },
        meta: {
          title: 'Resend verification email | Material Library',
          noNavigation: true,
        },
      },
      {
        path: 'registration/email/confirm/:token',
        name: 'registration-confirm',
        props: {
          content: (route) => ({
            token: route.params.token,
          }),
        },
        components: {
          content: () => import('@/views/Auth/RegistrationConfirm'),
        },
        meta: {
          title: 'Confirm registration | Material Library',
          noNavigation: true,
        },
      },
    ]
  },

  {
    path: '/main',
    component: () => import('@/components/Main'),
    children: [
      {
        path: 'materials',
        components: {
          content: () => import('@/views/Materials/NavigationView.vue'),
        },
        children: [
          {
            path: '',
            components: {
              container: () => import('@/views/Materials/CategoriesContainerView'),
            },
            children: [
              {
                path: 'all',
                name: 'materials:all',
                components: {
                  contentCentral: () => import('@/views/Materials/AllMaterials'),
                },
                meta: {
                  title: "Materials | All | Material Library",
                  isZoomAvailable: true,
                }
              },
              {
                path: 'workspace',
                name: 'workspace',
                redirect: { name: 'workspace:materials' },
                components: {
                  contentCentral: () => import('@/views/Materials/Workspace'),
                },
                meta: {
                  title: "Workspace | Material Library",
                  ability: ['read_my', 'Material'],
                  isZoomAvailable: true,
                },
                children: [
                  {
                    path: 'materials',
                    name: 'workspace:materials',
                    components: {
                      workspaceTab: () => import('@/views/Materials/MyMaterials'),
                    },
                    meta: {
                      title: "Workspace Materials | Material Library",
                      ability: ['read_my', 'Material'],
                      isZoomAvailable: true,
                    },
                  },
                  {
                    path: 'collections',
                    name: 'workspace:collections',
                    components: {
                      workspaceTab: () => import('@/views/Materials/MyCollections'),
                    },
                    meta: {
                      title: "Workspace Collections | Material Library",
                      ability: ['read_my', 'Collection']
                    },
                  },
                  {
                    path: 'favorite',
                    name: 'workspace:favorite',
                    components: {
                      workspaceTab: () => import('@/views/Materials/FavoriteMaterials'),
                    },
                    meta: {
                      title: "Favorite Materials | Material Library",
                      ability: ['read_my', 'Collection'],
                      isZoomAvailable: true,
                    },
                  },
                  {
                    path: 'complaints',
                    name: 'workspace:complaints',
                    components: {
                      workspaceTab: () => import('@/views/Complaints/WorkspaceComplaints'),
                    },
                    meta: {
                      title: "Workspace Complaints | Material Library",
                      ability: ['read_my', 'Complaint']
                    },
                  },
                  {
                    path: '',
                    redirect: { name: 'workspace:materials' },
                  },
                ],
              },
              {
                path: 'workspace/collections/:id',
                name: 'workspace:collection',
                components: {
                  contentCentral: () => import('@/views/Materials/MaterialsCollection'),
                },
                props: {
                  contentCentral: (route) => ({
                    collection: route.params.id,
                    collectionTitle: 'My Collection',
                    enableCollectionTracker: false,
                  }),
                },
                meta: {
                  title: "Workspace Collections | Material Library",
                  isZoomAvailable: true,
                },
              },

              {
                path: 'workspace/complaints/:id',
                name: 'workspace:complaint',
                components: {
                  contentCentral: () => import('@/views/Complaints/Complaint'),
                },
                props: {
                  contentCentral: (route) => ({
                    complaint: route.params.id,
                  }),
                },
                meta: {
                  title: "Workspace Complaint | Material Library",
                },
              },

              {
                path: '',
                redirect: { name: 'materials:all' },
              },
              {
                path: 'collections',
                name: 'materials:collections',
                components: {
                  contentCentral: () => import('@/views/Materials/AllCollections'),
                },
                meta: {
                  title: "Collections | Material Library",
                },
              },
              {
                path: 'collections/:id',
                name: 'materials:collection',
                components: {
                  contentCentral: () => import('@/views/Materials/MaterialsCollection'),
                },
                props: {
                  contentCentral: (route) => ({
                    collection: route.params.id,
                    enableCollectionTracker: true,
                  }),
                },
                meta: {
                  title: "Collection | Material Library",
                  isZoomAvailable: true,
                },
              },

              {
                path: 'management',
                name: 'materials:management',
                redirect: { name: 'materials:management:materials' },
                components: {
                  contentCentral: () => import('@/views/Materials/Management'),
                },
                meta: {
                  title: "Management | Material Library",
                  ability: ['read', 'Management'],
                },
                children: [
                  {
                    path: 'categories',
                    name: 'materials:management:categories',
                    components: {
                      managementTab: () => import('@/components/Management/ManagementCategories'),
                    },
                    meta: {
                      title: "Categories Management | Material Library",
                      ability: ['read', 'Management'],
                    },
                  },
                  {
                    path: 'tags',
                    name: 'materials:management:tags',
                    components: {
                      managementTab: () => import('@/components/Management/ManagementTags'),
                    },
                    meta: {
                      title: "Tags Management | Material Library",
                      ability: ['read', 'Management'],
                    },
                  },
                  {
                    path: 'materials',
                    name: 'materials:management:materials',
                    components: {
                      managementTab: () => import('@/components/Management/ManagementMaterials'),
                    },
                    meta: {
                      title: "Materials Management | Material Library",
                      ability: ['read', 'Management'],
                      isZoomAvailable: true,
                    },
                  },
                  {
                    path: 'complaints',
                    name: 'materials:management:complaints',
                    components: {
                      managementTab: () => import('@/views/Complaints/ManagementComplaints'),
                    },
                    meta: {
                      title: "Complaints Management | Material Library",
                      ability: ['read', 'Management'],
                    },
                  },
                ],
              },
              {
                path: 'management/complaints/:id',
                name: 'materials:management:complaint',
                components: {
                  contentCentral: () => import('@/views/Complaints/Complaint'),
                },
                props: {
                  contentCentral: (route) => ({
                    complaint: route.params.id,
                  }),
                },
                meta: {
                  title: "Complaint Management | Material Library",
                  ability: ['read', 'Management'],
                },
              },

            ]
          }
        ],
      },

      {
        path: 'account/me',
        name: 'account:me',
        components: {
          content: () => import('@/views/Account/UserProfile'),
        },
        meta: {
          title: 'My account | Material Library',
          ability: ['read_my', 'Account'],
        },
      },

      {
        path: 'error/:code',
        name: 'error',
        components: {
          content: () => import('@/views/Error'),
        },
        props: {
          content: (route) => ({
            code: route.params.code,
          }),
        },
        meta: {
          title: 'Error | Material Library',
        },
      },

      {
        path: 'policies/privacy',
        name: 'policies-cookies',
        components: {
          content: () => import('@/views/Policies/Privacy'),
        },
        meta: {
          title: 'Privacy Policy',
        },
      },

      {
        path: '',
        name: 'main',
        redirect: "materials",
      },
      {
        path: 'materials',
        redirect: { name: 'materials:all' },
      },
    ]
  },

  {
    path: '/',
    redirect: '/main',
  },

  {
    path: '*',
    name: 'error',
    component: () => import('@/views/Error'),
    props: { code: 404, },
    meta: { title: 'Error Page Not Found | Material Library', },
  },
];

const router = new Router({
  routes: routes,
  mode: 'history',
});

router.beforeEach( (to, from, next) => {
  const { ability } = to.meta;
  if (!ability) next();
  else {
    /*
      This code of abilityInstance placed here because of
      strange behaviour of Abilities plugin until Vue application mounted.
      Router initiazed before Vue and that method called before Vue mounted too.
      In that case Ability object contains in rules property (I dont know why).
    */
    let abilityInstance = Vue.prototype.$ability;
    if (!Array.isArray(abilityInstance.rules)) abilityInstance = abilityInstance.rules;
    if (abilityInstance.can(...ability)) next();
    else router.push({ name: 'error', params: { code: 403 } });
  }
});

router.afterEach((to) => {
  document.title = to.meta.title;
});

export default router;

import categories from './modules/categories';
import collections from './modules/collections';
import packages from './modules/packages';
import materials from './modules/materials';
import renders from './modules/renders';
import tags from './modules/tags';
import token from './modules/token';
import comments from './modules/comments';
import complaints from './modules/complaints';
import password from './modules/password';
import registration from './modules/registration';
import user from './modules/user';
import licenses from './modules/licenses'

export default {
  categories,
  collections,
  comments,
  complaints,
  materials,
  licenses,
  packages,
  password,
  registration,
  renders,
  tags,
  token,
  user,
};

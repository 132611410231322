<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-btn
      ref="btn"
      v-bind="$props"
      v-on="$listeners"
      class="d-flex flex-row align-center"
      :class="[hoveredClass(hover), buttonClass]"
    >
      <template #default>
        <div
          class="d-flex flex-row align-end"
          :class="{ 'z-1': onTop }"
        >
          <slot name="prepend">
            <v-icon
              v-if="prependIcon"
              :size="prependIconSize"
              :class="{
                'mr-2': $slots.default
              }"
            >
              {{ prependIcon }}
            </v-icon>
          </slot>

          <div :class="contentClass">
            <template v-if="(icon || fab)">
              <v-icon
                :size="iconSize"
                :color="mainIconColor"
              >
                {{ mainIcon }}
              </v-icon>

              <v-tooltip
                v-if="!noTooltip"
                :activator="$refs.btn"
                :bottom="tooltipDirection === 'bottom'"
                :top="tooltipDirection === 'top'"
                :left="tooltipDirection === 'left'"
                :right="tooltipDirection === 'right'"
              >
                <div :class="tooltipClass">
                  <slot name="default"></slot>
                </div>
              </v-tooltip>
            </template>

            <span v-else class="text-ellipsis overflow-hidden">
              <slot name="default"></slot>
            </span>
          </div>
        </div>

      </template>

      <template #loader>
        <slot name="loader"></slot>
      </template>
    </v-btn>
  </v-hover>
</template>

<script>
  import {
    VBtn,
  } from 'vuetify/lib';

  export default {
    name: 'AppButton',
    extends: VBtn,
    props: {
      mainIcon: {
        type: String,
      },
      mainIconColor: {
        type: String,
      },
      iconSize: {
        type: [String, Number],
      },
      prependIcon: {
        type: String,
      },
      prependIconSize: {
        type: [String, Number],
        default: '20',
      },
      primary: {
        type: Boolean,
        default: false,
      },
      hoverClass: {
        type: String,
        default() {
          const { icon, fab, primary } = this;
          return icon || fab || primary ? 'primary--text' : undefined;
        },
      },
      contentClass: {
        type: String,
      },
      tooltipClass: {
        type: String,
      },
      tooltipDirection: {
        type: String,
        default: "top",
      },
      noTooltip: {
        type: Boolean,
        default: false,
      },
      dense: {
        type: Boolean,
        default: true,
      },
      depressed: {
        type: Boolean,
        default: true,
      },
      onTop: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      buttonClass() {
        const { text, fab, icon, color } = this;
        return !text && color && !fab && !icon ? `outlined` : undefined;
      },

      vuetify() {
        return this.$vuetify;
      }
    },

    methods: {
      hoveredClass(hover) {
        return hover ? this.hoverClass : undefined;
      },
    },
  }
</script>

<style scoped>
  .v-btn.v-btn--outlined:not(.v-btn--icon):not(.v-btn--fab)::before {
    opacity: 1;
    filter: brightness(0.4);
  }
  .v-btn.v-btn--has-bg.outlined:not(.v-btn--icon):not(.v-btn--fab) {
    border-color: currentColor !important;
  }

  .theme--dark.v-btn:hover::before {
    opacity: 0.2 !important;
  }
</style>
<template>
  <v-app-bar
    flat
    app
    absolute
    height="82"
    class="align-end relative z-1"
  >
  <div class="col-12 pa-0 ma-0 d-flex flex-row justify-center align-end pt-2 pb-1">
    <div class="flex-equal d-flex flex-row align-end pb-2">
      <a :href="$config.urls.MAIN_SITE_URL">
        <v-img
          v-if="$vuetify.breakpoint.lgAndUp"
          contain
          height="50"
          max-width="180"
          width="180"
          src="@/assets/logo_white.svg"
          :href="$config.urls.MAIN_SITE_URL"
        />
        <v-img
          v-else
          contain
          height="50"
          max-width="50"
          width="50"
          src="@/assets/logo_mini.svg"
        />
      </a>

      <v-hover v-slot="{ hover }">
        <router-link
          v-if="$vuetify.breakpoint.smAndUp"
          to="/main"
          tag="div"
          class="text-decoration-none cursor-pointer px-5 fill-height d-flex align-center pb-2 pb-md-0 pb-lg-0 pb-xl-0"
        >
          <span
            class="display-4"
            :class="{
              'primary--text': hover,
            }"
          >MaterialX Library</span>
        </router-link>
      </v-hover>
    </div>

    <div class="flex-grow-2 d-flex flex-row align-end justify-center">
      <materials-type-navigation
        :iconed="$vuetify.breakpoint.smAndDown"
        class="z-0"
      />
    </div>

    <div class="flex-equal d-flex flex-row align-end justify-end">
      <app-menu
        v-if="$vuetify.breakpoint.xs"
        bottom
        no-gutters
        content-class="pa-2"
        absolute
        transition="slide-y-transition"
        :close-on-content-click="false"
      >
        <template #activator="{ on, value }">
          <app-button
            icon
            no-tooltip
            :main-icon="value ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            v-on="on"
            class="my-1"
          />
        </template>

        <app-card>
          <div class="d-flex flex-row">
            <app-button
              v-if="$can('read', $enums.Entity.MANAGEMENT)"
              :to="{ name: 'materials:management' }"
              icon
              main-icon="$vuetify.icons.management"
              active-class="primary--text"
              class="ma-1"
            >
              Management
            </app-button>

            <menu-help
              :text="require('@/assets/text/help_main.md').default"
              button-class="ma-1"
            ></menu-help>

            <div
              v-if="$config.urls.FEEDBACK_EMAIL"
              class="ma-1"
            >
              <menu-feedback/>
            </div>
          </div>
          <menu-avatar
            size="50"
            icon-size="32"
            avatar-color="background"
          />
        </app-card>
      </app-menu>

      <template v-else>
        <app-button
          v-if="$can('read', $enums.Entity.MANAGEMENT)"
          :to="{ name: 'materials:management' }"
          icon
          height="36"
          width="36"
          main-icon="$vuetify.icons.management"
          active-class="primary--text"
          class="ma-2 my-md-1 my-lg-1 my-xl-1"
        >
          Management
        </app-button>

        <menu-help
          :text="require('@/assets/text/help_main.md').default"
          button-class="ma-2 my-md-1 my-lg-1 my-xl-1"
          button-size="36"
        ></menu-help>

        <div
          v-if="$config.urls.FEEDBACK_EMAIL"
          class="ma-2 my-md-1 my-lg-1 my-xl-1"
        >
          <menu-feedback/>
        </div>

        <div class="pr-4"></div>

        <div class="pb-2">
          <menu-avatar
            size="50"
            icon-size="32"
            avatar-color="background"
          ></menu-avatar>
        </div>
      </template>
    </div>
  </div>
  </v-app-bar>
</template>

<script>
  import MenuAvatar from '@/components/Auth/MenuAvatar';
  import MenuFeedback from '@/components/MenuFeedback';
  import MenuHelp from '@/components/MenuHelp';
  import MaterialsTypeNavigation from '@/components/Materials/MaterialsTypeNavigation';
  import AppButton from '@/components/AppButton';
  import AppMenu from "./AppMenu";

  export default {
    name: "Navigation",

    components: {
      AppMenu,
      MenuAvatar,
      MenuFeedback,
      MaterialsTypeNavigation,
      AppButton,
      MenuHelp,
    },
  }
</script>
<template>
  <v-dialog
    :value="innerValue"
    @input="innerValue = $event"
    :width="width"
    :min-width="minWidth"
    :max-width="maxWidth"
    :height="height"
    :min-height="minHeight"
    :max-height="maxHeight"
    overlay-color="dialogOverlay"
    :content-class="contentClassDialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <slot name="addit"/>

    <app-sectioned-card
      :hide-header="hideHeader"
      width="100%"
      color="dialogBg"
      :height="height"
      :min-height="minHeight"
      :max-height="maxHeight"
      closable
      :always-content-scroll="alwaysContentScroll"
      :footer-class="footerClass"
      :content-class="contentClassV"
      :no-header-divider="noHeaderDivider"
      @click:close="close"
      class="d-flex flex-column rounded flex-grow-1 outlined border--color--dividers overflow-y-scroll"
    >
      <slot
        v-for="(_, name) in $slots"
        :name="name"
        :slot="name"
      />
    </app-sectioned-card>

    <template #activator="scope">
      <slot name="activator" v-bind="scope"></slot>
    </template>
  </v-dialog>
</template>

<script>
  import AppSectionedCard from '@/components/AppSectionedCard';

  export default {
    name: 'SectionedDialog',

    components: {
      AppSectionedCard,
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      height: {
        type: [String, Number],
        default: 'auto',
      },
      minHeight: {
        type: [String, Number],
        default: undefined,
      },
      maxHeight: {
        type: [String, Number],
        default: undefined,
      },
      noGutters: {
        type: Boolean,
        default: true,
      },
      width: {
        type: [String, Number],
        default: 'auto',
      },
      minWidth: {
        type: [String, Number],
        default: undefined
      },
      maxWidth: {
        type: [String, Number],
        default: 1300
      },
      alwaysContentScroll: {
        type: Boolean,
        default: true,
      },
      contentClass: {
        type: [ String, Object ],
      },
      footerClass: {
        type: [ String, Object ],
      },
      overflowVisible: {
        type: Boolean,
        default: false,
      },
      noHeaderDivider: {
        type: Boolean,
      },
      hideHeader: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        innerValue: false,
      }
    },

    computed: {
      hasHeaderContent() {
        return !!this.$slots.header || !!this.$slots['header-title'] || !!this.$slots['header-subtitle'];
      },

      hasDefaultContent() {
        return !!this.$slots.default;
      },

      hasFooterContent() {
        return !!this.$slots.footer || !!this.$slots['footer-actions'];
      },

      contentClassDialog() {
        const { overflowVisible, innerValue } = this;
        const optionalClass = innerValue ? 'd-flex flex-column' : '';
        const overflowClass = overflowVisible ? 'overflow-visible' : 'overflow-hidden'
        const defaultClass ='relative';
        return `${overflowClass} ${optionalClass} ${defaultClass}`
      },

      contentClassV() {
        const { contentClass, noGutters } = this;
        const optionalClass = noGutters ? '' : 'px-6 pb-6';
        return `${optionalClass} ${contentClass}`;
      },
    },

    watch: {
      value: {
        immediate: true,
        handler(v) {
          if (this.innerValue !== v) this.innerValue = v;
        },
      },
      innerValue: {
        handler(v) {
          this.$emit('input', v);
        },
      },
    },

    methods: {
      close() {
        this.innerValue = false;
      },
    }
  }
</script>

<template>
  <component
    :is="dialog ? 'SectionedDialog' : 'AppMenu'"
    v-model="menu"
    :max-width="maxWidth"
    :min-width="minWidth"
    :width="width"
    :max-height="maxHeight"
    :height="height"
    :close-on-content-click="false"
    :color="backgroundColor"
    :always-content-scroll="true"
    noHeaderDivider
    offset-x
    top
    left
    :no-gutters="false"
    transition="slide-y-transition"
  >
    <template #activator="scope">
      <slot
        name="activator"
        v-bind="scope"
      >
        <app-button
          :on-top="false"
          :small="smallButton"
          text
          :icon="iconed"
          :prepend-icon="!iconed ? icon : undefined"
          :main-icon="iconed ? icon : undefined"
          primary
          :height="iconed ? buttonHeight : undefined"
          :color="buttonColor"
          v-on="scope.on"
        >
          <template #default v-if="label">{{ label }}</template>
        </app-button>
      </slot>
    </template>

    <template #header-title>
      <div :class="titleClass">{{ title }}</div>
    </template>

    <template #footer-actions>
      <app-button
        outlined
        prepend-icon="$vuetify.icons.confirm"
        class="mx-auto"
        color="success"
        @click="menu = false;"
      >
        {{ text }}
      </app-button>
    </template>

    <slot name="content"></slot>
  </component>
</template>

<script>
  import SectionedDialog from '@/components/SectionedDialog';
  import AppMenu from '@/components/AppMenu';

  export default {
    name: 'ConfirmationPopup',

    components: { SectionedDialog, AppMenu },

    props: {
      label: String,
      title: String,
      icon: String,
      buttonColor: String,
      buttonHeight: [String, Number],
      backgroundColor: {
        type: String,
        default: 'dialogBg'
      },
      smallButton: {
        type: Boolean,
        default: false,
      },
      contentClass: String,
      dialog: {
        type: Boolean,
        default: false,
      },
      iconed: {
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
        default: 'I understand'
      },
      titleClass: {
        type: String,
        default: 'display-3'
      },
      width: {
        type: [String, Number],
      },
      minWidth: {
        type: [String, Number],
        default: 300,
      },
      maxWidth: {
        type: [String, Number],
        default: 380,
      },
      maxHeight: {
        type: [Number, String],
      },
      height: {
        type: [Number, String],
      },
    },

    data() {
      return {
        menu: false,
      };
    },
  }
</script>

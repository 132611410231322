<template functional>
  <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24">
    <polyline points="2,6 2,8 14,8 14,6 2,6 "/>
    <polyline points="2,10 2,12 11,12 11,10 2,10 "/>
    <rect x="2" y="14" width="9" height="2"/>
    <path d="M16.8,10.3c1.2,0,2.3,1,2.3,2.3s-1,2.3-2.3,2.3s-2.3-1-2.3-2.3S15.5,10.3,16.8,10.3"/>
    <path d="M12.3,19.3v-1.1c0-1.2,2-2.2,4.5-2.2s4.5,1,4.5,2.2v1.1H12.3z"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconReview'
  }
</script>

import objectsUtils from './objects';
import axios from 'axios';

export default {
  async fetchAllList({ getAll, getNext, target, mapPropertiesObject, targetLoading, targetObject, targetLoadingKey, targetCancel, filter, options }) {
    return new Promise( (resolve, reject) => {
      targetObject[targetLoading] = true;
      targetObject[target] = [];

      let loadingKey;
      if (targetLoadingKey) {
        loadingKey = targetObject[targetLoadingKey] + 1;
        targetObject[targetLoadingKey] = loadingKey;
      }

      this.fetchList({ getAll, target, mapPropertiesObject, targetLoading, targetObject, targetCancel, targetLoadingKey, loadingKey, filter, options })
      .then( next => {
        this.fetchListNextRecursive({ getNext, target, targetObject, mapPropertiesObject, targetCancel, targetLoadingKey, loadingKey, next, options })
        .then( () => {
          targetObject[targetLoading] = false;
          resolve();
        })
        .catch( () => {
          targetObject[targetLoading] = false;
          reject();
        });

      })
      .catch( () => {
        targetObject[targetLoading] = false;
        reject();
      });
    });
  },

  fetchList({ getAll, target, mapPropertiesObject, targetLoading, targetObject, targetCancel, targetLoadingKey, loadingKey, filter, options }) {
    return new Promise( (resolve, reject) => {
      targetObject[targetLoading] = true;

      if (targetCancel) {
        let { cancelToken, cancel } = this.generateCancel();
        targetObject[targetCancel] = cancel;
        options = { ...options, cancelToken };
      }

      getAll(filter, options)
      .then( response => {
        const { data } = response;
        targetObject[target] = data.results;
        if (targetLoadingKey && loadingKey && targetObject[targetLoadingKey] !== loadingKey) return resolve();
        objectsUtils.mapProperties(targetObject, data, mapPropertiesObject);
        resolve(data.next);
      })
      .catch( () => {
        reject();
      });
    });
  },

  fetchListNextRecursive({ getNext, target, targetObject, targetLoadingKey, loadingKey, targetCancel, mapPropertiesObject, next, options }) {
    return new Promise( (resolve, reject) => {
      if (!next) return resolve();

      if (targetCancel) {
        let { cancelToken, cancel } = this.generateCancel();
        targetObject[targetCancel] = cancel;
        options = { ...options, cancelToken };
      }

      getNext(next, options)
      .then( (response) => {
        const { data } = response;
        targetObject[target].push(...data.results);
        if (targetLoadingKey && loadingKey && targetObject[targetLoadingKey] !== loadingKey) return resolve();
        objectsUtils.mapProperties(targetObject, data, mapPropertiesObject);
        this.fetchListNextRecursive({
          getNext: getNext,
          next: data.next,
          target,
          targetObject,
          targetCancel,
        })
        .then( resolve );
      })
      .catch( reject );
    })
  },

  async fetchAllStart({ getAll, targetObject, mapPropertiesObject, targetNext, targetLoading, targetCancel, targetLoadingKey, filter, options }) {
    return new Promise( (resolve, reject) => {
      targetObject[targetLoading] = true;
      targetObject[targetNext] = null;

      if (targetCancel) {
        let { cancelToken, cancel } = this.generateCancel();
        targetObject[targetCancel] = cancel;
        options = { ...options, cancelToken };
      }
      let loadingKeyNew;
      if (targetLoadingKey) {
        loadingKeyNew = targetObject[targetLoadingKey] + 1;
        targetObject[targetLoadingKey] = loadingKeyNew;
      }

      getAll(filter, options)
      .then( async (response) => {
        const { data } = response;
        targetObject[targetNext] = data.next;
        if (targetLoadingKey && loadingKeyNew && targetObject[targetLoadingKey] !== loadingKeyNew) return resolve();
        await objectsUtils.mapProperties(targetObject, data, mapPropertiesObject);
        targetObject[targetLoading] = false;
        resolve(response);
      })
      .catch( (error) => {
        targetObject[targetLoading] = false;
        reject(error);
      })
    });
  },

  fetchAllNext({ getNext, targetObject, mapPropertiesObject, pushPropertiesObject, targetNext, targetLoading, targetCancel, targetLoadingKey, loadingKey, options }) {
    return new Promise( (resolve, reject) => {
      if (!targetObject[targetNext]) return resolve();

      if (targetObject[targetLoading]) return resolve();

      targetObject[targetLoading] = true;

      if (targetCancel) {
        let { cancelToken, cancel } = this.generateCancel();
        targetObject[targetCancel] = cancel;
        options = { ...options, cancelToken };
      }

      getNext(targetObject[targetNext], options)
      .then( (response) => {
        const { data } = response;
        if (targetLoadingKey && targetObject[targetLoadingKey] !== loadingKey) return resolve();
        objectsUtils.mapProperties(targetObject, data, mapPropertiesObject);
        if (targetLoadingKey && targetObject[targetLoadingKey] !== loadingKey) return resolve();
        objectsUtils.pushProperties(targetObject, data, pushPropertiesObject);
        targetObject[targetNext] = data.next;
        targetObject[targetLoading] = false;
        resolve(response);
      })
      .catch( (error) => {
        targetObject[targetLoading] = false;
        reject(error);
      });
    });
  },

  async getNext(url) {
    return axios({
      method: 'get',
      url: url,
    });
  },

  getMessageFromError(error, property) {
    if (property) {
      let data = error?.response?.data;
      return (data?.[property] ?? data?.detail) ?? error?.message;
    }
    return error?.message;
  },

  generateError(message) {
    if (typeof message === 'string') message = ({ detail: message });
    return ({ respose: { data: message } })
  },

  getJsonMessages(data) {
    let ignoreKeys = [ 'code' ];
    let noPrefixKeys = [ 'detail', 'non_field_errors' ]
    let result = [];
    const capitalize = (v) => v.charAt(0).toUpperCase() + v.slice(1);

    if (data && typeof data === 'object') {
      Object.entries(data).forEach( ([ key, value ]) => {
        if (ignoreKeys.includes(key)) return;
        if (!isNaN(+key)) key = '';
        if (noPrefixKeys.includes(key)) key = '';
        if (key) key = `${capitalize(key)}: `
        if (!Array.isArray(value)) value = [ value ];
        value.forEach( it => {
          if (typeof it !== 'string') return;
          result.push(`${key}${it}`)
        })
      });
    }
    return result;
  },

  getErrorMessages(error) {
    let result = this.getJsonMessages(error?.response?.data);
    const status = error?.response?.status;
    const SPECIAL_STATUS_MESSAGES = {
      500: 'Internal Server Error',
      501: 'Method is not allowed',
      503: 'Service is Unavailable. Try later',
    };

    if (result.length < 1 && status && SPECIAL_STATUS_MESSAGES[status]) result.push(SPECIAL_STATUS_MESSAGES[status]);
    if (result.length < 1 && error?.message) result.push(error.message);
    if (result.length < 1) result.push('Request finished with error');
    return result;
  },

  getResponseMessages(response) {
    let result = this.getJsonMessages(response?.data);
    if (result.length < 1) result.push('Request finished successfully');
    return result;
  },

  generateCancel() {
    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    const cancel = () => source.cancel('aborted_by_user');

    return { source, cancelToken, cancel };
  },
};

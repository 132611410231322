function toArray(v) {
  return !Array.isArray(v) ? [ v ] : v;
}

export default {
  methods: {

    filterByAbilities(array, disablableKey) {
      if (!Array.isArray(array)) return [];

      let filterItem = (it) => {
        if (!('ability' in it)) return true;
        let ability = toArray(it.ability)
        let [ actions, models ] = ability;
        actions = toArray(actions);
        models = toArray(models);
        return actions.some( action => models.some( model => this.$can(action, model) || (disablableKey && model[disablableKey]) ) );
      }

      return array.filter(filterItem);
    },

  },
};

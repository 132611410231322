import axios from 'axios';
import requestsUtils from '@/utils/requests';
import store from '@/store';

const ENDPOINT = 'registration';

export default {

  ENDPOINT,

  register({ username, email, password1, password2, firstName, lastName }) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/`,
      data: {
        username,
        email,
        password1,
        password2,
        first_name: firstName,
        last_name: lastName,
      },
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', 'Registration failed');
      return Promise.reject(error);
    });
  },

  resendActivationEmail({ username }) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/resend_activation_email/`,
      data: {
        username,
      },
    })
    .then( (response) => {
      requestsUtils.getResponseMessages(response)
      .forEach( (message) => store.dispatch('notification/success', message));
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', 'Activation email sending failed');
      return Promise.reject(error);
    });
  },

  confirm({ key }) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/verify_email/`,
      data: {
        key,
      },
    });
  },
};

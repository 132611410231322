import axios from 'axios';
import urlsUtils from '@/utils/urls';
import config from '@/config';
import store from '@/store';

const ENDPOINT = 'materials';

export default {

  ENDPOINT,

  async getAllForUser(filter, options) {
    filter = {
      ...filter,
      limit: config.restrictions.MATERIALS_LOADING_LIMIT,
    };
    options = {
      ...options,
      method: 'get',
      url: urlsUtils.constructQueryUrl(`${ENDPOINT}/workspace/`, filter),
    };
    return axios(options)
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error", "User materials loading error");
      return Promise.reject(error);
    });
  },

  async getAll(filter, options) {
    filter = {
      ...filter,
      limit: config.restrictions.MATERIALS_LOADING_LIMIT,
    };
    options = {
      ...options,
      method: 'get',
      url: urlsUtils.constructQueryUrl(`${ENDPOINT}/`, filter),
    };
    return axios(options)
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error) && error?.response?.status !== 401) store.dispatch("notification/error", "Materials loading error");
      return Promise.reject(error);
    });
  },

  async getNext(url, options) {
    options = {
      ...options,
      method: 'get',
      url,
    };
    return axios(options)
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error) && error?.response?.status !== 401) store.dispatch("notification/error", "Materials loading error");
      return Promise.reject(error);
    });
  },

  async get(idOrIds) {
    if (Array.isArray(idOrIds)) return Promise.all( idOrIds.map(this.get) );

    return axios({
      method: 'get',
      url: `${ENDPOINT}/${idOrIds}/`,
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error", `Material ${idOrIds} loading error`);
      return Promise.reject(error);
    });
  },

  async getData(idOrIds) {
    return this.get(idOrIds)
    .then( (result) => {
      if (Array.isArray(result)) return Promise.resolve(result.map( r => r.data));
      return Promise.resolve(result.data);
    })
    .catch( (error) => Promise.reject(error) );
  },

  async getContributors() {
    return axios({
      method: 'get',
      url: `${ENDPOINT}/contributors/`,
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error", `Contributors loading error`);
      return Promise.reject(error);
    });
  },

  async getContributorsData() {
    return this.getContributors()
    .then( (response) => Promise.resolve(response.data?.authors) )
    .catch( (error) => Promise.reject(error) );
  },

  async count(filter) {
    return axios({
      method: 'get',
      url: urlsUtils.constructQueryUrl(`${ENDPOINT}/count/`, filter),
    })
  },

  async countNumber(filter) {
    return this.count(filter)
    .then( (response) => Promise.resolve(response.data.count) )
    .catch( (error) => Promise.reject(error) );
  },

  async add({
    title,
    packages,
    renders,
    author,
    published,
    category,
    license,
    collection,
    tags,
    description,
    material_type,
    highlights,
  }) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/`,
      data: {
        title,
        packages,
        renders,
        author,
        published,
        category,
        license,
        collection,
        description,
        tags,
        material_type,
        highlights,
      }
    })
    .then( (response) => {
      store.dispatch('notification/success', `Material ${response.data?.title} created`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Material ${title} creation error`);
      return Promise.reject(error);
    });
  },

  async edit(id, {
    title,
    packages,
    renders,
    author,
    published,
    category,
    license,
    collection,
    tags,
    description,
    material_type,
    highlights,
  }) {
    return axios({
      method: 'put',
      url: `${ENDPOINT}/${id}/`,
      data: {
        title,
        packages,
        renders,
        author,
        published,
        category,
        license,
        collection,
        tags,
        description,
        material_type,
        highlights,
      }
    })
    .then( (response) => {
      store.dispatch('notification/success', `Material ${response.data?.title} saved`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Material ${title} editing error`);
      return Promise.reject(error);
    });
  },

  async patch({ id, title }, editData) {
    return axios({
      method: 'patch',
      url: `${ENDPOINT}/${id}/`,
      data: editData,
    })
    .then( (response) => {
      store.dispatch('notification/success', `Material ${response.data?.title} saved`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Material ${title} editing error`);
      return Promise.reject(error);
    });
  },

  async delete({ id, title }) {
    return axios({
      method: 'delete',
      url: `${ENDPOINT}/${id}/`,
    })
    .then( (response) => {
      store.dispatch('notification/success', `Material ${title} deleted`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      store.dispatch('notification/error', `Material ${title} deletion error`);
      return Promise.reject(error);
    });
  },

  async publish(id) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/${id}/publish/`,
    })
    .then( (response) => {
      store.dispatch('notification/success', `Material ${response.data?.title} published`);
      return Promise.resolve(response);
    })
    .catch( (error) => Promise.reject(error) );
  },

  async unpublish(id) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/${id}/unpublish/`,
    })
    .then( (response) => {
      store.dispatch('notification/success', `Material ${response.data?.title} unpublished`);
      return Promise.resolve(response);
    })
    .catch( (error) => Promise.reject(error) );
  },

  async sendToReview(id) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/${id}/send_for_review/`,
    })
    .then( (response) => {
      store.dispatch('notification/success', `Material ${response.data?.title} sent to review`);
      return Promise.resolve(response);
    })
    .catch( (error) => Promise.reject(error) );
  },

  async revertFromReview(id) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/${id}/cancel_review/`,
    })
    .then( (response) => {
      store.dispatch('notification/success', `Material ${response.data?.title} reverted from review`);
      return Promise.resolve(response);
    })
    .catch( (error) => Promise.reject(error) );
  },

  async addToFavorites({ id, title }) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/${id}/add_to_favorite/`,
    })
    .then( (response) => {
      title ??= id;
      store.dispatch('notification/success', `Material ${title} added to favorites.
        You can find it at Workspace Collections`);
      return Promise.resolve(response);
    })
    .catch( (error) => Promise.reject(error) );
  },

  async removeFromFavorites({ id, title }) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/${id}/remove_from_favorite/`,
    })
    .then( (response) => {
      title ??= id;
      store.dispatch('notification/success', `Material ${title} removed from favorites`);
      return Promise.resolve(response);
    })
    .catch( (error) => Promise.reject(error) );
  },

  getViewerSrc({ materialId, previewId, materialTitle }) {
    const isViewerDebug = (process.env.VUE_APP_VIEWER_DEBUG ?? 'false') === 'true'
    const materialPath = isViewerDebug ?
      'demo/demo' :
      `${materialId}/${materialTitle?.replaceAll(':', '').replaceAll(' ', '_')}`;
    const previewUrl = `${config.urls.IMAGE_CDN_URL}/${previewId}_preview.jpeg`;
    return `${config.urls.VIEWER_URL}/?material=public/materials/${materialPath}.mtlx&previewUrl=${previewUrl}`;
  },

  async enableViewer({ id }) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/${id}/enable_3d_viewer/`,
    });
  },

  async disableViewer({ id }) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/${id}/disable_3d_viewer/`,
    });
  },
};

<template functional>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" height="24" width="24" class="svg-icon">
    <polyline fill="#f35325" points="1,1 1,11 11,11 11,1"/>
    <polyline fill="#81bc06" points="12,1 12,11 22,11 22,1"/>
    <polyline fill="#ffba08" points="22,12 12,12 12,22 22, 22"/>
    <polyline fill="#05a6f0" points="1,12 11,12 11,22 1,22"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconMicrosoft',
  }
</script>

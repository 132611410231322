<template>
  <app-menu
    v-model="menu"
    max-width="400"
    min-width="300"
    bottom
    :close-on-content-click="false"
    transition="slide-y-transition"
  >

    <template v-slot:activator="{ on }">
      <app-button
        icon
        main-icon="$vuetify.icons.feedback"
        height="36"
        width="36"
        hover-class="primary--text"
        v-on="on"
      >
        Feedback
      </app-button>
    </template>

    <template #header-title>
      Send us your feedback
    </template>
    <div>
      It helps us make our service better. Please send your feedback to our email.
    </div>
    <div class="mt-2">
      <v-icon class="mr-2">
        $vuetify.icons.email
      </v-icon>
      <a
        class="simple-link"
        :href="`mailto:${$config.urls.FEEDBACK_EMAIL}`"
      >{{ $config.urls.FEEDBACK_EMAIL }}</a>
    </div>
  </app-menu>
</template>

<script>
  import rules from '@/utils/inputRules';
  import AppMenu from '@/components/AppMenu';

  export default {
    name: 'MenuFeedback',

    data() {
      return {
        menu: false,
        loadingSend: false,
        message: '',
        email: '',
        rules: { ...rules },
      };
    },

    components: {
      AppMenu,
    },

    methods: {
      sendFeedback() {
        if (!this.$refs.form.validate()) return;
        this.loadingSend = true;
        setTimeout( () => {
          this.reset();
          this.$store.dispatch('notification/success', 'Feedback sent.');
        }, 300);
      },

      reset() {
        this.loadingSend = false;
        this.message = '';
        this.email = '';
        this.$refs.form.reset();
      },
    },
  }
</script>

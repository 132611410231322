<template>
  <app-card
    v-bind="$props"
    v-on="$listeners"
  >
    <div
      v-if="hasHeaderContent || !hideHeader"
      class="flex-grow-0 flex-shrink-0"
    >
      <div class="px-6 pt-6">
        <slot name="header">
          <div class="display-3 text-capitalize">
            <slot name="header-title"></slot>
          </div>
          <div class="title font-weight-light subsecondary--text">
            <slot name="header-subtitle"></slot>
          </div>
        </slot>
      </div>
      <div class="px-4 mt-2"><v-divider v-if="!noHeaderDivider"/></div>
    </div>

    <div
      v-if="hasDefaultContent"
      class="flex-grow-1"
      :class="[ {
        'overflow-y-scroll': alwaysContentScroll,
      }, contentClass ]"
    >
      <slot name="default"></slot>
    </div>

    <div
      v-if="hasFooterContent"
      :class="footerClass"
      class="flex-grow-0 flex-shrink-0"
    >
      <div class="px-4"><v-divider v-if="!noFooterDivider"/></div>
      <slot name="footer">
        <div class="d-flex flex-row flex-wrap align-center justify-start pa-3 pt-1">
          <slot name="footer-actions"></slot>
        </div>
      </slot>
    </div>

    <div class="absolute top-0 right-0 pa-1 d-flex flex-row align-center justify-end">
      <slot name="actions"></slot>
      <app-button
        v-if="closable"
        icon
        :icon-size="buttonCloseIconSize"
        :small="buttonCloseSmall"
        :class="{
          'ma-1': buttonCloseSmall
        }"
        main-icon="$vuetify.icons.close"
        @click="$emit('click:close')"
      >
        Close
      </app-button>
    </div>

  </app-card>
</template>

<script>
  import AppCard from '@/components/AppCard';

  export default {
    name: 'AppSectionedCard',

    extends: AppCard,

    props: {
      closable: {
        type: Boolean,
        default: false,
      },
      noHeaderDivider: {
        type: Boolean,
        default: false,
      },
      noFooterDivider: {
        type: Boolean,
        default: true,
      },
      contentClass: {
        type: [ String, Object ],
      },
      footerClass: {
        type: [ String, Object ],
      },
      alwaysContentScroll: {
        type: Boolean,
        default: true,
      },
      buttonCloseSmall: {
        type: Boolean,
        default: false,
      },
      buttonCloseIconSize: {
        type: [String, Number],
        default: '30',
      },
      hideHeader: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      hasHeaderContent() {
        return !!this.$slots.header || !!this.$slots['header-title'] || !!this.$slots['header-subtitle'];
      },

      hasDefaultContent() {
        return !!this.$slots.default;
      },

      hasFooterContent() {
        return !!this.$slots.footer || !!this.$slots['footer-actions'];
      },
    },
  }
</script>
import axios from 'axios';
import urlsUtils from '@/utils/urls';
import store from '@/store';
import config from '@/config';

const ENDPOINT = 'categories';

export default {

  ENDPOINT,

  async getAll(filter) {
    filter = {
      ordering: '+title',
      ...filter,
      limit: config.restrictions.CATEGORIES_LOADING_LIMIT,
    };
    return axios({
      method: 'get',
      url: urlsUtils.constructQueryUrl(`${ENDPOINT}/`, filter),
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error) && error?.response?.status !== 401) store.dispatch("notification/error", "Categories loading error");
      return Promise.reject(error);
    });
  },

  async getNext(url) {
    return axios({
      method: 'get',
      url: url,
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error) && error?.response?.status !== 401) store.dispatch("notification/error", "Categories next loading error");
      return Promise.reject(error);
    });
  },

  async get(idOrIds) {
    if (Array.isArray(idOrIds)) return Promise.all( idOrIds.map(this.get) );

    return axios({
      method: 'get',
      url: `${ENDPOINT}/${idOrIds}/`,
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error", `Category ${idOrIds} loading error`);
      return Promise.reject(error);
    });
  },

  async getData(idOrIds) {
    return this.get(idOrIds)
    .then( result => {
      if (Array.isArray(result)) return Promise.resolve(result.map( r => r.data));

      return Promise.resolve(result.data);
    })
    .catch( Promise.reject );
  },

  async count() {
    return axios({
      method: 'get',
      url: `${ENDPOINT}/count/`,
    })
  },

  async add({
    title,
  }) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/`,
      data: {
        title,
      }
    })
    .then( (response) => {
      store.dispatch('notification/success', `Category ${response.data?.title} created`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Category ${title} creation error`);
      return Promise.reject(error);
    });
  },

  async edit(id, {
    title,
  }) {
    return axios({
      method: 'put',
      url: `${ENDPOINT}/${id}/`,
      data: {
        title,
      }
    })
    .then( (response) => {
      store.dispatch('notification/success', `Category ${response.data?.title} saved`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Category ${id} editing error`);
      return Promise.reject(error);
    });
  },

  async patch(id, editData) {
    return axios({
      method: 'patch',
      url: `${ENDPOINT}/${id}/`,
      data: editData,
    })
    .then( (response) => {
      store.dispatch('notification/success', `Category ${response.data?.title} saved`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Category ${id} editing error`);
      return Promise.reject(error);
    });
  },

  async delete(id) {
    return axios({
      method: 'delete',
      url: `${ENDPOINT}/${id}/`,
    })
    .then( (response) => {
      store.dispatch('notification/success', `Category ${id} deleted`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Category ${id} deletion error`);
      return Promise.reject(error);
    });
  },
};

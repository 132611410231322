import tokenAPI from '@/api/modules/token';
import userAPI from '@/api/modules/user';
import requestsUtils from '@/utils/requests';
import browserActions from '@/utils/browserActions';
import eventBus from '@/eventBus';
import axios from 'axios';

let user = null;
try {
  user = browserActions.getStoredUser();
  tokenAPI.setupTokenByUser(user);
  if (!tokenAPI.isUserValid(user)) user = null
} catch (error) {
  user = null;
  tokenAPI.logout()
}

const state = {
  user: user,
  isRefreshing: false,
  wasLoggedOutExpired: false,
  cancel: undefined,
};

const getters = {
  isLoggedIn: state => !!state.user,

  username: state => state.user?.username,

  firstName: state => state.user?.first_name,

  lastName: state => state.user?.last_name,

  groups: state => state.user?.groups,

  email: state => state.user?.email,

  accessToken: state => state.user?.access_token,

  refreshToken: state => state.user?.refresh_token,

  avatar: state => state.user?.avatar,

  avatarThumbnail: state => state.user?.thumbnail,

  wasLoggedOutExpired: state => state.wasLoggedOutExpired,
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },

  logout(state) {
    state.user = null;
  },

  setRefreshing(state, { value, cancel }) {
    state.isRefreshing = value;
    state.cancel = cancel;
  },

  setLoggedOutExpired(state, value) {
    state.wasLoggedOutExpired = value;
  },
};

const actions = {
  login({ dispatch, commit }, { username, password }) {
    return new Promise( (resolve, reject) => {
      commit('setLoggedOutExpired', false);
      tokenAPI.login({ username, password })
      .then( user => {
        commit('setUser', user);
        dispatch('notification/success', 'Successfully logged in', { root: true });
        resolve(user);
      })
      .catch( error => {
        dispatch('logoutSilent');
        reject(error);
      });
    });
  },

  loginOAuth({ dispatch, commit }, { user, provider }) {
    let endpoint = tokenAPI.SOCIAL_ENDPOINTS[provider];
    if (endpoint) return new Promise( (resolve, reject) => {
        const { access_token, code, id_token } = user;
        tokenAPI.oauth({ access_token, code, id_token }, endpoint)
        .then(user => {
          commit('setUser', user);
          dispatch('notification/success', 'Successfully logged in', { root: true });
          resolve(user);
        })
        .catch(error => {
          dispatch('logoutSilent');
          reject(error);
        });
      });
    else throw new Error('You should set provider argument for authorization');
  },

  connectSocial({ dispatch }, { user, provider }) {
    let endpoint = tokenAPI.SOCIAL_ENDPOINTS[provider];
    if (endpoint) return new Promise( (resolve, reject) => {
        const { access_token, code, id_token } = user;
        tokenAPI.oauthСonnect({ access_token, code, id_token }, endpoint)
        .then( () => {
          dispatch('notification/success', 'Social account connected', { root: true });
          resolve();
        })
        .catch(error => {
          reject(error);
        });
      });
    else throw new Error('You should set provider argument for authorization');
  },

  async refreshAndSet({ commit, state, dispatch }, { invalid_access_token }) {
    const { cancel, cancelToken } = requestsUtils.generateCancel();
    commit('setRefreshing', { value: false, cancel });
    return new Promise( (resolve, reject) => {
      if (!state.user) return reject();
      //handle if another tab refresh
      let storedUser, beforeExp;
      try{
        storedUser = browserActions.getStoredUser();
        beforeExp = browserActions.extractJWTExpTimeout(storedUser.access_token);
      } catch (error) {
        return reject(error)
      }

      // handle if another browser tab refreshed token so return stored
      if (invalid_access_token !== storedUser.access_token && beforeExp > tokenAPI.REFRESH_THRESHOLD) {
        let user = browserActions.getStoredUser();
        tokenAPI.setupTokenByUser(user);
        commit('setRefreshing', { value: false, cancel: undefined });
        commit('setUser', user);
        resolve(user);
      }

      tokenAPI.refresh({ cancelToken, invalid_access_token })
      .then( () => {
        let user = browserActions.getStoredUser();
        commit('setUser', user);
        commit('setRefreshing', { value: false, cancel: undefined });
        userAPI.getData().then( () => { user = browserActions.getStoredUser(); });
        commit('setUser', user);
        resolve(user);
      })
      .catch( error => {
        commit('setRefreshing', { value: false, cancel: undefined });
        if (axios.isCancel(error)) return reject(error);
        //handle if many tabs refreshed token immediately
        let storedUser = browserActions.getStoredUser();
        let beforeExp = browserActions.extractJWTExpTimeout(storedUser?.access_token);
        if (beforeExp > tokenAPI.REFRESH_THRESHOLD) {
          tokenAPI.verify(storedUser?.access_token)
          .then( () => {
            commit('setUser', user);
            resolve(storedUser);
          })
        } else {
          dispatch('logoutSilent');
          reject(error);
        }
      })
    });
  },

  async verifyAndRefresh({ state, dispatch }) {
    return new Promise( (resolve, reject) => {
      if (!state.user) return reject();
      const { access_token } = state.user
      tokenAPI.verify(access_token)
      .then( () => {
        resolve(state.user);
      })
      .catch( () => {
        dispatch('refreshIfNotRefreshing', { invalid_access_token: access_token })
        .then(resolve)
        .catch(reject);
      });
    });
  },

  async refreshIfNotRefreshing({ state, dispatch }, { invalid_access_token }) {
    return new Promise( (resolve, reject) => {
      if (state.isRefreshing) {
        // if already waiting response with refreshing data, subscribe on setUser mutation
        // if it was setUser mutation, that means token was refreshed
        this.subscribe( ({ type, payload }) => {
          if (type == 'account/setUser') resolve(payload);
        });
      } else {
        dispatch('refreshAndSet', { invalid_access_token })
        .then(resolve)
        .catch(reject);
      }
    });
  },

  async refresh({ dispatch, state }) {
    return new Promise( (resolve, reject) => {
      if (state.isRefreshing) {
        // if already waiting response with refreshing data, subscribe on setUser mutation
        // if it was setUser mutation, that means token was refreshed
        this.subscribe( ({ type, payload }) => {
          if (type == 'account/setUser') resolve(payload);
        });
      } else {
        dispatch('verifyAndRefresh')
        .then(resolve)
        .catch(reject);
      }
    });
  },

  async cancelRefresh({ commit, state }) {
    if (!state.cancel) state.cancel();
    commit('setRefreshing', { value: false, cancel: undefined });
  },

  async patchUser({ state, commit }, { username, firstName, lastName }) {
    return new Promise( (resolve, reject) => {
      userAPI.patch({ username, firstName, lastName })
      .then( (response) => {
        let newUser = { ...state.user, ...response.data };
        commit('setUser', newUser);
        browserActions.setStoredUser({ ...browserActions.getStoredUser(), ...newUser })
        resolve();
      })
      .catch(reject);
    });
  },

  async patchUserAvatar({ state, commit }, { file, onUploadProgress, cancelToken }) {
    return new Promise( (resolve, reject) => {
      userAPI.editAvatar({ file, onUploadProgress, cancelToken })
        .then( (response) => {
          let newUser = { ...state.user, ...response.data };
          commit('setUser', newUser);
          browserActions.setStoredUser({ ...browserActions.getStoredUser(), ...newUser })
          resolve();
        })
        .catch(reject);
    });
  },

  isStoredUserValid({ state }) {
    return tokenAPI.isUserValid(state.user)
  },

  async logoutSilent({ commit, state }) {
    let refresh_token = state.user?.refresh_token;
    tokenAPI.logout({ refresh_token });
    commit('logout');
    return Promise.resolve()
  },

  logout({ dispatch }) {
    dispatch('logoutSilent');
    dispatch('notification/success', `Logged out`, { root: true });
    eventBus.$emit('logout');
  },
};

export const account = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

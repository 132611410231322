import axios from 'axios';
import requestsUtils from '@/utils/requests';
import store from '@/store';

const ENDPOINT = 'auth/password';

export default {

  ENDPOINT,

  async change({ oldPassword, newPassword1, newPassword2 }) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/change/`,
      data: {
        old_password: oldPassword,
        new_password1: newPassword1,
        new_password2: newPassword2,
      },
    })
    .then( (response) => {
      requestsUtils.getResponseMessages(response)
      .forEach( (message) => store.dispatch('notification/success', message));
      return Promise.resolve(response);
    });
  },

  async reset({ email }) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/reset/`,
      data: {
        email,
      },
    })
    .then( (response) => {
      requestsUtils.getResponseMessages(response)
      .forEach( (message) => store.dispatch('notification/success', message));
      return Promise.resolve(response);
    });
  },

  async confirmReset({ newPassword1, newPassword2, uid, token }) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/reset/confirm/`,
      data: {
        new_password1: newPassword1,
        new_password2: newPassword2,
        uid,
        token,
      },
    })
    .then( (response) => {
      requestsUtils.getResponseMessages(response)
      .forEach( (message) => store.dispatch('notification/success', message));
      return Promise.resolve(response);
    });
  },

};
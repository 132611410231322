import axios from "axios";
import urlsUtils from '@/utils/urls';
import store from '@/store';

const ENDPOINT = 'licenses';

export default {

  ENDPOINT,

  async getAll(filter, options) {
    options = {
      ...options,
      method: 'get',
      url: urlsUtils.constructQueryUrl(`${ENDPOINT}/`, filter),
    };
    return axios(options)
      .then( res => Promise.resolve(res) )
      .catch( er => {
        if (!axios.isCancel(er)) store.dispatch("notification/error", "Licenses loading error");
        return Promise.reject(er);
      })
  },

  async getNext(url, options) {
    options = {
      ...options,
      method: 'get',
      url,
    };
    return axios(options)
      .then( res => Promise.resolve(res) )
      .catch( er => {
        if (!axios.isCancel(er)) store.dispatch("notification/error", "Licenses loading error");
        return Promise.reject(er);
      });
  },

  async get(idOrIds) {
    if (Array.isArray(idOrIds)) return Promise.all( idOrIds.map(this.get) );
    return axios({
      method: 'get',
      url: `${ENDPOINT}/${idOrIds}/`,
    })
      .then( res => Promise.resolve(res) )
      .catch( er => {
        if (!axios.isCancel(er)) store.dispatch("notification/error", `Material ${idOrIds} loading error`);
        return Promise.reject(er);
      });
  },

  async getLicensesData(onlyNames = false, getInternalLicense = false) {
    return this.getAll()
      .then( res => Promise.resolve(res
        .data
        ?.results
        ?.filter( it => it.name !== (getInternalLicense ? '' : 'license') )
        ?.map( it => onlyNames ? it.name : it ))
      )
      .catch( er => Promise.reject(er) );
  }
}
import axios from 'axios';
import urlsUtils from '@/utils/urls';
import store from '@/store';
import config from '@/config';

const ENDPOINT = 'renders';
const CDN_URL = config.urls.IMAGE_CDN_URL;

export default {

  ENDPOINT,

  async getAll(filter) {
    return axios({
      method: 'get',
      url: urlsUtils.constructQueryUrl(`${ENDPOINT}/`, filter),
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error", "Renders loading error");
      return Promise.reject(error);
    });
  },

  async getNext(url) {
    return axios({
      method: 'get',
      url: url,
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error", "Renders loading error");
      return Promise.reject(error);
    });
  },

  async get(idOrIds) {
    if (Array.isArray(idOrIds)) return Promise.all( idOrIds.map(this.get) );

    return axios({
      method: 'get',
      url: `${ENDPOINT}/${idOrIds}/`,
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error",  `Render ${idOrIds} loading error`);
      return Promise.reject(error);
    });
  },

  async getData(idOrIds) {
    return this.get(idOrIds)
    .then( result => {
      if (Array.isArray(result)) return Promise.resolve(result.map( r => r.data));

      return Promise.resolve(result.data);
    })
    .catch( Promise.reject );
  },

  getRenderSrc(id) {
    return id ?
        CDN_URL ? `${CDN_URL}/${id}.jpeg` : `${axios.defaults.baseURL}${ENDPOINT}/${id}/download/`
        : undefined;
  },

  getThumbnailSrc(id) {
    return id ?
        CDN_URL ? `${CDN_URL}/${id}_thumbnail.jpeg` : `${axios.defaults.baseURL}${ENDPOINT}/${id}/download_thumbnail/`
        : undefined;
  },

  getPreviewSrc(id) {
    return id ?
      CDN_URL ? `${CDN_URL}/${id}_preview.jpeg` : `${axios.defaults.baseURL}${ENDPOINT}/${id}/download_preview/`
      : undefined;
  },

  async count() {
    return axios({
      method: 'get',
      url: `${ENDPOINT}/count/`,
    })
  },

  async getUnattached() {
    return axios({
      method: 'get',
      url: `${ENDPOINT}/cleanup/`,
    })
    .then( res => Promise.resolve(res?.data) );
  },

  async add({ file, onUploadProgress, cancelToken }) {
    if (file.size > config.restrictions.RENDER_FILE_SIZE_MAX) {
      store.dispatch('notification/error', `Size of file ${file.name} overflows restriction`);
      return Promise.reject();
    }

    let bodyFormData = new FormData();
    bodyFormData.append('image', file);

    return axios({
      method: 'post',
      url: `${ENDPOINT}/`,
      headers: { "Content-Type": "multipart/form-data" },
      data: bodyFormData,
      onUploadProgress,
      cancelToken,
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Render ${file?.name} uploading error`);
      return Promise.reject(error);
    });
  },

  async edit(id, file) {
    if (file.size > config.restrictions.RENDER_FILE_SIZE_MAX) {
      store.dispatch('notification/error', `Size of file ${file.name} overflows restriction`);
      return Promise.reject();
    }

    let bodyFormData = new FormData();
    bodyFormData.append('image', file);

    return axios({
      method: 'put',
      url: `${ENDPOINT}/${id}/`,
      headers: { "Content-Type": "multipart/form-data" },
      data: bodyFormData,
    })
    .then( response => {
      store.dispatch('notification/success', `Render ${response.data?.image} saved`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Render ${id} editing error`);
      return Promise.reject(error);
    });
  },

  async patch(id, editData) {
    return axios({
      method: 'patch',
      url: `${ENDPOINT}/${id}/`,
      data: editData,
    })
    .then( response => {
      store.dispatch('notification/success', `Render ${response.data?.image} saved`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Render ${id} editing error`);
      return Promise.reject(error);
    });
  },

  async delete(id) {
    return axios({
      method: 'delete',
      url: `${ENDPOINT}/${id}/`,
    })
    .then( (response) => {
      store.dispatch('notification/success', `Render ${id} deleted`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Render ${id} deletion error`);
      return Promise.reject(error);
    });
  },

  async deleteUnattached() {
    return axios({
      method: 'delete',
      url: `${ENDPOINT}/cleanup/`,
    })
    .then( res => {
      store.dispatch('notification/success', `Successfully clean renders`);
      return Promise.resolve(res?.data)
    } )
    .catch( Promise.reject );
  },
};
<template functional>
  <svg height="24" width="24" class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M2,6v2h12V6H2 M2,10v2h12v-2H2 M2,14v2h8v-2H2z"/>
    <path d="M13.8,17.4"/>
    <path d="M10.5,15.4"/>
    <polygon points="14,16.7 18.2,20.9 19.7,19.5 15.4,15.3 14,13.9 12.6,15.3 8.3,19.5 9.8,20.9 "/>
  </svg>
</template>

<script>
  export default {
    name: 'IconСreate',
  }
</script>

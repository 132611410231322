import axios from 'axios';
import urlsUtils from '@/utils/urls';
import store from '@/store';

const ENDPOINT = 'collections';

export default {

  ENDPOINT,

  async getAllForUser(filter) {
    return axios({
      method: 'get',
      url: urlsUtils.constructQueryUrl(`${ENDPOINT}/workspace/`, filter),
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error", "User collections loading error");
      return Promise.reject(error);
    });
  },

  async getAll(filter) {
    return axios({
      method: 'get',
      url: urlsUtils.constructQueryUrl(`${ENDPOINT}/`, filter),
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error", "Collections loading error");
      return Promise.reject(error);
    });
  },

  async getNext(url) {
    return axios({
      method: 'get',
      url: url,
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error", "Collections loading error");
      return Promise.reject(error);
    });
  },

  async get(idOrIds) {
    if (Array.isArray(idOrIds)) return Promise.all( idOrIds.map(this.get) );

    return axios({
      method: 'get',
      url: `${ENDPOINT}/${idOrIds}/`,
    })
    .then( (response) => Promise.resolve(response) )
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch("notification/error", `Collection ${idOrIds} loading error`);
      return Promise.reject(error);
    });
  },

  async getData(idOrIds) {
    return this.get(idOrIds)
    .then( result => {
      if (Array.isArray(result)) return Promise.resolve(result.map( r => r.data));

      return Promise.resolve(result.data);
    })
    .catch( Promise.reject );
  },

  async count() {
    return axios({
      method: 'get',
      url: `${ENDPOINT}/count/`,
    });
  },

  async getFavorite() {
    return axios({
      method: 'get',
      url: `${ENDPOINT}/favorite/`,
    });
  },

  async add({
    title,
    description,
    materials,
    cover,
    status,
  }) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/`,
      data: {
        title,
        description,
        materials,
        cover,
        status,
      }
    })
    .then( (response) => {
      store.dispatch('notification/success', `Collection ${response.data?.title} created`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Collection ${title} creation error`);
      return Promise.reject(error);
    });
  },

  async edit(id, {
    title,
  }) {
    return axios({
      method: 'put',
      url: `${ENDPOINT}/${id}/`,
      data: {
        title,
      }
    })
    .then( (response) => {
      store.dispatch('notification/success', `Collection ${response.data?.title} saved`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Collection ${id} editing error`);
      return Promise.reject(error);
    });
  },

  async patch(id, editData) {
    return axios({
      method: 'patch',
      url: `${ENDPOINT}/${id}/`,
      data: editData,
    })
    .then( (response) => {
      store.dispatch('notification/success', `Collection ${response.data?.title} saved`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Collection ${id} editing error`);
      return Promise.reject(error);
    });
  },

  async delete(id) {
    return axios({
      method: 'delete',
      url: `${ENDPOINT}/${id}/`,
    })
    .then( (response) => {
      store.dispatch('notification/success', `Collection ${id} deleted`);
      return Promise.resolve(response);
    })
    .catch( (error) => {
      if (!axios.isCancel(error)) store.dispatch('notification/error', `Collection ${id} deletion error`);
      return Promise.reject(error);
    });
  },

  async publish(id) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/${id}/publish/`,
    })
    .then( (response) => {
      store.dispatch('notification/success', `Collection ${response.data?.title} published`);
      return Promise.resolve(response);
    })
    .catch( (error) => Promise.reject(error) );
  },

  async unpublish(id) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/${id}/unpublish/`,
    })
    .then( (response) => {
      store.dispatch('notification/success', `Collection ${response.data?.title} unpublished`);
      return Promise.resolve(response);
    })
    .catch( (error) => Promise.reject(error) );
  },

  async addMaterial(collectionId, materialId) {
    return axios({
      method: 'post',
      url: `${ENDPOINT}/${collectionId}/add_material/`,
      data: {
        material_id: materialId,
      },
    })
    .then( (response) => {
      store.dispatch('notification/success', `Material ${materialId} added to collection ${collectionId}`);
      return Promise.resolve(response);
    })
    .catch( (error) => Promise.reject(error) );
  },
};

<template>
  <ConfirmationPopup
    dialog
    :title="title"
    max-width="700"
    max-height="90vh"
  >
    <template #activator="{ on }">
      <slot
        name="activator"
        :on="on"
      >
        <app-button
          v-on="on"
          icon
          :height="buttonSize"
          :width="buttonSize"
          main-icon="$vuetify.icons.help"
          :class="buttonClass"
        >
          Help
        </app-button>
      </slot>
    </template>

    <template #content>
      <formatted-text
        :text="text"
        :content-type="textContentType"
      >
      </formatted-text>
    </template>
  </ConfirmationPopup>
</template>

<script>
  import ConfirmationPopup from '@/components/ConfirmationPopup';
  import FormattedText from '@/components/FormattedText';

  export default {
    name: 'MenuHelp',

    components: {
      ConfirmationPopup,
      FormattedText,
    },

    props: {
      buttonClass: {
        type: String
      },
      buttonSize: {
        type: [String, Number]
      },
      text: {
        type: String,
      },
      title: {
        type: String,
      },
      textContentType: {
        type: String,
        default: 'text/markdown',
      },
    },

  }
</script> 